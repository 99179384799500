import { Typography, Box, Grid, Container } from "@mui/material";
import SeoCard from "./SeoCard";

const styles = {
  root: {
    bgcolor: "#FFFDF5",
    padding: 4,
    marginBottom: 6,
    "@media (max-width: 600px)": {
      pt: 5,
    },
  },
  HeaderText: {
    fontWeight: 800,
    fontSize: "48px",
    lineHeight: "55px",
    mb: 10,
    "@media (max-width: 600px)": {
      fontSize: "1.3rem",
      letterSpacing: "0",
      lineHeight: "20px",
      mb: 6,
    },
  },
};

const items = [
  {
    title: "BACKLINK",
    description:
      "a BACKLINK IS A LINK FROM ANOTHER WEBSITE THAT POINTS TO YOUR WEBSITE. THE MAIN ADVANTAGE OF BACKLINKS IS TO INCREASE THE CREDIBILITY AND SEO POWER OF YOUR WEBSITE IN SEARCH ENGINES.",
  },
  {
    title: "E-COMMERCE SEO",
    description: "Optimizing online stores, product pages, and implementing strategies to improve visibility in search results.",
  },
  {
    title: "KEYWORD ANALYSIS AND OPTIMIZATION",
    description: "ANALYZING AND SELECTING RELEVANT KEYWORDS FOR WEBSITE CONTENT AND OPTIMIZING THEM TO IMPROVE RANKINGS.",
  },
  {
    title: "ON-PAGE OPTIMIZATION",
    description:
      "OPTIMIZING TECHNICAL ASPECTS OF THE WEBSITE, INCLUDING USING TITLE TAGS, META DESCRIPTIONS, URL STRUCTURES, AND OTHER IMPORTANT FACTORS.",
  },
  {
    title: "CONTENT OPTIMIZATION",
    description:
      "IMPROVING WEBSITE CONTENT BY USING APPROPRIATE KEYWORDS, CREATING PROPER PAGE STRUCTURES, AND PROVIDING VALUABLE AND DIVERSE CONTENT.",
  },
  {
    title: "QUALITY LINK BUILDING",
    description:
      "ATTRACTING HIGH-QUALITY EXTERNAL LINKS FROM REPUTABLE AND RELEVANT WEBSITES TO INCREASE WEBSITE CREDIBILITY AND SEARCH ENGINE RANKINGS.",
  },
  {
    title: "USER EXPERIENCE IMPROVEMENT",
    description: "Enhancing user interface, page loading speed, optimizing mobile versions, and providing a better and user-friendly experience.",
  },
  {
    title: "SITE NAVIGATION",
    description: "Enhancing user interface, page loading speed, optimizing mobile versions, and providing a better and user-friendly experience.",
  },
  {
    title: "ANALYSIS AND MONITORING",
    description:
      "WITH FEATURES LIKE SEARCH ANALYTICS AND CRAWL STATISTICS, BUSINESSES CAN OPTIMIZE THEIR WEBSITE TO IMPROVE OVERALL SEARCH ENGINE VISIBILITY.",
  },
];

export const WhatWeDo = () => {
  return (
    <Container sx={styles.root}>
      <Typography variant="h3" textAlign={"center"} sx={styles.HeaderText}>
        What we do for you!
      </Typography>

      <Grid container spacing={4} sx={{ justifyContent: "center" }}>
        {items.map((item, index) => (
          <Grid key={index} item xs={12} sm={12} md={4} lg={4} xl={4}>
            <SeoCard item={item} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
