import { Typography, Box, Stack } from "@mui/material";
function MemberCard({ imageSrc, name, role, description }) {
  const styles = {
    stack: {},
  };
  return (
    <Stack sx={{ maxWidth: "400px", maxHeight: "500px" }} alignItems={"center"}>
      <Box component="img" src={imageSrc} alt={name} maxWidth={"300px"} maxHeight={"350px"} />

      <Box sx={{ marginTop: "-50px", marginLeft: "-100px", minWidth: "380px", maxWidth: "400px" }}>
        <Typography variant={name === "Amir mostafa Haji sadeghian" ? "h5" : "h4"} textTransform={"uppercase"}>
          {name}
        </Typography>
        <Typography variant="h6" textTransform={"uppercase"}>
          {role}
        </Typography>
      </Box>

      <Box>
        <Typography>{description}</Typography>
      </Box>
    </Stack>
  );
}

export default MemberCard;
