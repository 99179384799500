import { Box, Container, Stack, Typography } from "@mui/material";
import serviceImage from "../../../assets/build-website/service1.svg";
import backServiceImage from "../../../assets/build-website/back-service1.svg";

export const ServiceSection1 = () => {
  return (
    <Box py={{ xs: 16, md: 24 }}>
      <Container>
        <Stack direction={{ xs: "column-reverse", md: "row" }} justifyContent="center" alignItems="center">
          <Box flex={1}>
            <Typography
              variant="h3"
              letterSpacing={{ xs: 3, md: 6 }}
              fontWeight="bold"
              fontSize={{ xs: "24px", md: "2rem", lg: "3rem" }}
              sx={{
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: "50%",
                  display: { xs: "none", sm: "inline" },
                  left: { xs: "14rem", md: "20rem", lg: "28rem" },
                  border: "gray 1px solid",
                  width: { xs: "40px", md: "50px", lg: "80px" },
                },
              }}
            >
              BUILD WEBSITE
            </Typography>
            <Typography variant="body1" mt={2} lineHeight="28px" paddingRight={"15px"} fontSize={{ xs: 14, md: 16 }} textAlign="justify">
              In this technology-based era where consumers find information, services, and products online, websites have become the most important
              component in business marketing. Every step of erecting your website must be handled professionally If you want it to deliver as
              anticipated. Change is constant in all aspects of your website, which is why you need a website development company with expansive
              experience and knowledge in all the intricate aspects of this realm.
            </Typography>
          </Box>
          <Box flex={1} position="relative" display="flex" justifyContent="center" alignItems="center" mt={{ xs: 12, md: 0 }} mb={{ xs: 24, md: 0 }}>
            <Box
              component="img"
              src={backServiceImage}
              alt="background-service-image"
              position="absolute"
              right={{ xs: "-115px", md: "28px", lg: "75px" }}
              bottom={{ xs: "-65px", md: "-108px", lg: "-108px" }}
              width={{ xs: "252px", md: "402px" }}
            />
            <Box component="img" src={serviceImage} alt="service-image" position="absolute" width={{ xs: "255px", md: "405px" }} />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
