import { Typography, Box, Stack, Container } from "@mui/material";
import fImg from "../../../assets/home/Frame 130.png";
import sImg from "../../../assets/home/Frame 131.png";
import tImg from "../../../assets/home/Frame 132.png";

const styles = {
  root: { paddingTop: "5rem" },
  text1: {
    color: "#B9B9B9B9",
    fontSize: { xs: "13px", sm: "18px", md: "22px" },
    letterSpacing: "3px",
  },
  text2: {
    letterSpacing: ".3rem",
  },
  para: {
    maxWidth: "36rem",
    lineHeight: "1.8rem",
    marginBottom: "3rem",
  },
  boxes: {
    width: "24rem",
    color: { xs: "#000", lg: "#FFF" },
  },
  containerBoxes: {
    display: "flex",
    justifyContent: "center",
    flexWrap: { xs: "wrap", lg: "nowrap" },
    gap: "2rem 5rem",
  },
  bgBottom: {
    width: "100%",
    height: "35rem",
    backgroundColor: "primary.main",
    marginTop: "-25rem",
  },
};
export const About = () => {
  return (
    <>
      <Container sx={styles.root}>
        <Typography component="h2" sx={styles.text1} fontFamily="Lemon Milk Font" gutterBottom>
          ABOUT US
        </Typography>
        <Typography component="h3" variant="h3" sx={styles.text2} gutterBottom>
          WEB DEVELOPMENT & DESIGN COMPANY
        </Typography>
        <Typography component="p" variant="body1" sx={styles.para}>
          We are a web design and web development company, furnishing fresh, creative digital services to businesses who want to grow online.
        </Typography>

        <Box sx={styles.containerBoxes}>
          <Stack sx={styles.boxes}>
            <Box component="img" maxWidth="100%" src={fImg} marginBottom="1.5rem" />
            <Typography component="h3" variant="h5" gutterBottom>
              OUR STRATEGY
            </Typography>
            <Typography>
              At APSLegacy, we believe that the best digital marketing strategies are the ones that are focused on the human element. We understand
              that people are not just faceless consumers; they are individuals with their own needs, wants, and desires. That's why we focus on
              developing digital marketing strategies that are human-centric.
            </Typography>
          </Stack>
          <Stack sx={styles.boxes}>
            <Box component="img" maxWidth="100%" src={sImg} marginBottom="1.5rem" />
            <Typography component="h3" variant="h5" gutterBottom>
              OUR VISION
            </Typography>
            <Typography>
              To be the leading digital marketing agency in the world, renowned for our expertise, creativity, and commitment to helping our clients
              succeed.
            </Typography>
          </Stack>
          <Stack sx={styles.boxes}>
            <Box component="img" src={tImg} maxWidth="100%" marginBottom="1.5rem" />
            <Typography component="h3" variant="h5" gutterBottom color="#fff">
              OUR MISSION
            </Typography>
            <Typography color="#fff">
              To help brands of all sizes achieve their online marketing goals through creative, effective, and authentic digital marketing solutions.
            </Typography>
          </Stack>
        </Box>
      </Container>
      <Box sx={styles.bgBottom}></Box>
    </>
  );
};
