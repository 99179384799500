import { Box, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import { keyframes } from "@emotion/react";

const slideShow = keyframes`
    100% {
      transform: translateX(-2000px);
    }
`;

const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "primary.main",
    color: "#FFF",
    padding: "1rem 0",
    width: "4000px",
    animation: `${slideShow} 50s linear infinite`,
  },
  boxes: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  icons: {
    fontSize: "3.3rem",
  },
};
export const Slider = () => {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.boxes}>
        <Typography variant="h3">WEB DEVELOPMENT</Typography>
        <EditIcon sx={styles.icons} />
      </Box>
      <Box sx={styles.boxes}>
        <Typography variant="h3">UI/UX</Typography>
        <AutoFixHighIcon sx={styles.icons} />
      </Box>
      <Box sx={styles.boxes}>
        <Typography variant="h3">SEO</Typography>
        <WorkspacesIcon sx={styles.icons} />
      </Box>
      <Box sx={styles.boxes}>
        <Typography variant="h3">CONTENT CREATION</Typography>
        <ContentCutIcon sx={styles.icons} />
      </Box>
      <Box sx={styles.boxes}>
        <Typography variant="h3">WEB DEVELOPMENT</Typography>
        <EditIcon sx={styles.icons} />
      </Box>
      <Box sx={styles.boxes}>
        <Typography variant="h3">UI/UX</Typography>
        <AutoFixHighIcon sx={styles.icons} />
      </Box>
      <Box sx={styles.boxes}>
        <Typography variant="h3">SEO</Typography>
        <WorkspacesIcon sx={styles.icons} />
      </Box>
      <Box sx={styles.boxes}>
        <Typography variant="h3">CONTENT CREATION</Typography>
        <ContentCutIcon sx={styles.icons} />
      </Box>
    </Box>
  );
};
