import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const SampleContentCard = ({ title, description, width, count }) => {
  const rndInt = count % 4 === 0 ? 2 : 1;
  const colCount = [5, 7, 4, 8, 6, 6];
  return (
    <Grid
      sx={{
        borderTop: "1px solid black",
        borderRight: {
          xs: "unset",
          md: count % 2 === 0 ? "1px solid black" : "unset",
          lg: count % 2 === 0 ? "1px solid black" : "unset",
        },
        p: "30px",
      }}
      xs={12}
      md={colCount[count]}
      lg={colCount[count]}
      // md={count % 2 === 0 ? 6 : 6}
      // lg={count % 2 === 0 ? 6 - rndInt : 6 + rndInt}
    >
      <Typography sx={{ py: "20px", fontWeight: 800 }} variant="h5">
        {title}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          lineHeight: "22px",
          // width: "200px",
          //  maxWidth:{lg: "200px" , md:"unset", xs:"unset"} ,
          textAlign: "justify",
        }}
      >
        {description}
      </Typography>
    </Grid>
  );
};

export default SampleContentCard;
