import React from "react";
import CompareTable from "./CompareTable";
import { Typography, Box, Container, Stack } from "@mui/material";
import FunctionalityTable from "./FunctionalityTable";
import SeoTable from "./SeoTable";
import HumanResources from "./HumanResources";

function Pricing(props) {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          px: "10px",
          //   width: "100%",
        }}
      >
        <Typography variant="h3">SMART SOLUTIONS, CLEAR PRICING!</Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "rgb(34, 0, 35)",
          color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "30px",
          py: "30px",
          mt: "50px",
          pb: "500px",
          px: "10px",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          SELECT THE BEST PLAN FOR
        </Typography>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          YOUR WEBSITE
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
          <Typography variant="caption">FROM BASIC TO PROFESSIONAL, WE HAVE A WEB DESIGN PLAN THAT'S RIGHT FOR</Typography>
          <Typography variant="caption">YOU. COMPARE OUR OPTIONS AND SELECT THE ONE THAT MEETS YOUR NEEDS.</Typography>
        </Box>
      </Box>
      <Box sx={{ px: { xs: "10px", lg: "100px", ml: "50px" }, mt: "-450px" }}>
        <CompareTable />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", my: "100px", backgroundColor: "rgb(34, 0, 35)", color: "white", border: "1px solid white" }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <Typography variant="body2" sx={{ p: "20px", flexBasis: { md: "100%", lg: "30%", xs: "100%" }, border: "1px solid white" }}>
            INCLUDED SERVICE:
          </Typography>
          <Typography variant="body2" sx={{ p: "20px", flexBasis: { md: "100%", lg: "70%", xs: "100%" }, border: "1px solid white" }}>
            WORDPRESS WEBSITE TUTORIAL
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <Typography variant="body2" sx={{ p: "20px", flexBasis: { md: "100%", lg: "30%", xs: "100%" }, border: "1px solid white" }}>
            DESCRIPTION:
          </Typography>
          <Typography variant="body2" sx={{ p: "20px", flexBasis: { md: "100%", lg: "70%", xs: "100%" }, border: "1px solid white" }}>
            LEARN HOW TO ADD/DELETE IMAGES, CATEGORIES, AND TEXT TO YOUR SITE
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row",
          flexWrap: "wrap",
          gap: "50px",
          justifyContent: "center",
          alignItems: "center",
          mb: "50px",
          px: "30px",
        }}
      >
        <FunctionalityTable />
        <SeoTable />
      </Box>
      <Box sx={{ backgroundColor: "rgb(34, 0, 35)", py: "50px", mt: "-15px" }}>
        <Container>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "10px", pb: "20px" }}>
            <Box component={"h4"} color={"white"} fontWeight={"bolder"} fontFamily={"Lemon Milk Font"} fontSize={"40px"}>
              Code Harmony: Elevate Your Website with
              <br />
              Affordable Brilliance and Exceptional Service!
            </Box>
          </Box>
          <Typography variant="body2" textAlign={"justify"} sx={{ mt: "20px", lineHeight: "30px" }} color={"white"} textTransform={"uppercase"}>
            Discover our Coding Development Price Table, showcasing competitive rates for top-notch services – your roadmap to exceptional web
            solutions without breaking the bank.
          </Typography>
        </Container>
      </Box>
      <Stack justifyContent={"center"} alignItems={"center"} marginY={15} marginX={2}>
        <HumanResources />
      </Stack>
    </div>
  );
}

export default Pricing;
