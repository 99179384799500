import { Box, Container } from "@mui/system";
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
  //  Menu,
  //  MenuItem,
  //  Fade,
  //  IconButton
} from "@mui/material";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import {
  Link,
  // useNavigate
} from "react-router-dom";
import Logo from "../../assets/legacy-logo.png";
import DrawerComp from "./DrawerComp";

function Header(props) {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [scroll, setScroll] = useState(0);
  // const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = (route) => {
  //   setAnchorEl(null);
  //   typeof route === "string" && navigate(route);
  // };

  const styles = {
    scrolledContainer: { boxShadow: theme.shadows, zIndex: "100", position: "fixed", width: "100%", background: "#ffffff" },
    container: { boxShadow: "none" },
    appBar: { background: "#FFFDF5", padding: "10px", boxShadow: "none", position: "static" },
    toolbar: { display: "flex", justifyContent: "space-between" },
    logoWrapper: { flexBasis: { lg: "20%" } },
    linksWrapper: { display: "flex", justifyContent: "space-between", flexBasis: { lg: "45%" } },
    item: { marginRight: "20px" },
    linkItem: {
      textDecoration: "none",
      color: "#000",
    },
    logoStyle: { width: "100%", maxWidth: "10vw", minWidth: "8rem" },
    distance: { maxHeight: "85px", height: "10vh" },
  };
  return (
    <>
      <Box sx={scroll ? styles.scrolledContainer : styles.container}>
        <Container>
          <AppBar sx={{ ...styles.appBar }}>
            <Toolbar sx={styles.toolbar}>
              <Box sx={styles.logoWrapper}>
                <Box component="img" sx={styles.logoStyle} src={Logo} alt="logo" />
              </Box>
              {isMatch ? (
                <DrawerComp />
              ) : (
                <Box sx={{ ...styles.linksWrapper }}>
                  <Box sx={styles.item}>
                    <Link style={styles.linkItem} to="/">
                      Home
                    </Link>
                  </Box>
                  <Box sx={{ ...styles.item }}>
                    <Link style={styles.linkItem} to="/services">
                      Services
                    </Link>
                    {/*
                    <IconButton
                      aria-controls={open ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      sx={{
                        padding: 0,
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      <MenuItem onClick={() => handleClose("/seo")}>SEO</MenuItem>
                      <MenuItem onClick={() => handleClose("/design")}>Design</MenuItem>
                    </Menu>
                     */}
                  </Box>
                  <Box sx={styles.item}>
                    <Link style={styles.linkItem} to="/build-website">
                      Build Website
                    </Link>
                  </Box>
                  <Box sx={styles.item}>
                    <Link style={styles.linkItem} to="/pricing">
                      Pricing
                    </Link>
                  </Box>
                  <Box sx={styles.item}>
                    <Link style={styles.linkItem} to="/about">
                      About
                    </Link>
                  </Box>
                </Box>
              )}
            </Toolbar>
          </AppBar>
        </Container>
      </Box>
      <Box sx={scroll ? styles.distance : null}></Box>
    </>
  );
}

export default Header;
