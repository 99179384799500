import { Typography, Box, Container, Stack, Grid } from "@mui/material";
import grid1 from "../../../assets/services/Image grid1.png";
import grid2 from "../../../assets/services/Image grid2.png";
import grid3 from "../../../assets/services/Image grid3.png";
import grid4 from "../../../assets/services/Image grid4.png";
import { Link } from "react-router-dom";

const styles = {
  root: {
    padding: "3rem 0",
  },
  text: {
    textAlign: "center",
  },
  textJustify: {
    textAlign: "justify",
    textAlignLast: "center",
  },
  boxes: {
    width: { xs: "20rem", sm: "22rem" },
    minHeight: { xs: "20rem", sm: "22rem" },
    backgroundColor: "primary.main",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  gridContainer: {
    maxWidth: { xs: "23rem", md: "50rem", lg: "74rem" },
  },
  underlineAnimation: {
    transition: "all 0.3s",
    position: "relative",
    "&::after": {
      content: '""',
      width: "100%",
      height: "2px",
      position: "absolute",
      bottom: 0,
      left: 0,
      backgroundColor: "#fff",
      transform: "scaleX(0.5)",
      transformOrigin: "bottom left",
      transition: "transform 0.25s ease-out",
    },
    "&:hover::after": {
      transform: "scaleX(1)",
      height: "2.5px",
      transformOrigin: "bottom left",
    },
  },
};

export const OurServices = () => {
  return (
    <Container maxWidth="xl" sx={styles.root}>
      <Typography gutterBottom component="h3" variant="h3" fontWeight="bold" sx={styles.text}>
        Our{" "}
        <Box component="span" color="#6D336D">
          Services
        </Box>{" "}
      </Typography>
      <Stack justifyContent="center" direction="row">
        <Typography
          padding="1rem 1rem 0 1rem"
          component="p"
          variant="body1"
          maxWidth="40rem"
          sx={{ ...styles.text, ...styles.textJustify }}
          gutterBottom
        >
          Embark on your digital journey with confidence as APSlegacy stands as your steadfast partner in the ever-evolving landscape of the online
          world. With a commitment to excellence, we proudly offer a spectrum of services and solutions. Your success is our priority. At APSlegacy,
          we don't just provide services; we craft digital experiences of the highest quality. Step boldly into the future of your online presence,
          knowing that our team is here to support and elevate your vision. Your success story begins with us. let's create it together.
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center" marginTop="2rem">
        <Grid container spacing={5} sx={styles.gridContainer} justifyContent="center" direction="row">
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={styles.boxes}>
              <Typography variant="h5" gutterBottom>
                design
              </Typography>
              <Typography component="p" variant="caption" padding="10px" sx={styles.textJustify}>
                Our design service combines aesthetics and functionality to deliver visually compelling solutions across graphic, web, and user
                experience design. We focus on creating designs that resonate with your audience and achieve your specific goals, enhancing your
                brand's visual appeal and impact.
              </Typography>
              <Link to={"/design"}>
                <Typography component="span" sx={styles.underlineAnimation}>
                  See more
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={styles.boxes} component="img" src={grid1}></Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={styles.boxes}>
              <Typography variant="h5" gutterBottom>
                seo
              </Typography>
              <Typography component="p" variant="caption" padding="10px" sx={styles.textJustify}>
                SEO and content creation work together to boost online visibility, optimizing websites for search engines and engaging target
                audiences with valuable content. The synergy between them is essential for successful digital presence.
              </Typography>
              <Link to={"/seo"}>
                <Typography component="span" sx={styles.underlineAnimation}>
                  See more
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={styles.boxes} component="img" src={grid2}></Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={styles.boxes}>
              <Typography variant="h5" gutterBottom>
                build website
              </Typography>
              <Typography component="p" variant="caption" padding="10px" sx={styles.textJustify}>
                Our website building service crafts seamless online experiences tailored to your needs. From responsive design to user-friendly
                navigation, we create websites that captivate audiences and drive engagement. Elevate your online presence with our expertise,
                delivering functional and visually appealing websites.
              </Typography>
              <Link to={"/build-website"}>
                <Typography component="span" sx={styles.underlineAnimation}>
                  See more
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={styles.boxes} component="img" src={grid3}></Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={styles.boxes}>
              <Typography variant="h5" gutterBottom>
                COMPANY REGISTRATION (UK)
              </Typography>
              <Typography component="p" variant="caption" padding="10px" sx={styles.textJustify}>
                In the UK, register your company with Companies House by choosing a unique name, defining the structure, and providing necessary
                details. Once completed, receive a certificate of incorporation, establishing your company's legal presence and transparency in the
                public record.
              </Typography>
              <Link to={"/"}>
                <Typography component="span" sx={styles.underlineAnimation}>
                  See more
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={styles.boxes} component="img" src={grid4}></Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box sx={styles.boxes}>
              <Typography variant="h5" gutterBottom>
                HOSTING AND DOMAIN
              </Typography>
              <Typography component="p" variant="caption" padding="10px" sx={styles.textJustify}>
                Empower your online presence with our hosting and domain service—secure, reliable hosting coupled with seamless domain management. We
                provide the foundation for a robust and accessible digital space, ensuring a smooth and secure online experience.
              </Typography>
              <Link to={"/"}>
                <Typography component="span" sx={styles.underlineAnimation}>
                  See more
                </Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};
