import React from "react";
import { Typography, Box } from "@mui/material";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  Number: {
    color: "rgba(234, 234, 234, 0.5)",
    fontSize: "100px",
    lineHeight: "135px",
    fontWeight: 900,
  },
  Title: {
    color: "#fff",
    fontSize: "24px",
    lineHeight: "40px",
    textAlign: "start",
    fontWeight: 800,
  },
  Describtion: {
    color: "#fff",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "justify",
    fontFamily: "Montserrat",
  },
};

const SeoItem = ({ number, title, subtitle }) => {
  return (
    <Box position="relative" pl={{ xs: 0, lg: "4rem" }}>
      <Typography
        variant="subtitle1"
        position={{ xs: "static", lg: "absolute" }}
        left={{ xs: "0rem", lg: number === 1 ? "0" : "-2%" }}
        top={{ xs: "0px", lg: number === 1 ? "-65%" : "-42.5%" }}
        color="rgba(234, 234, 234, 0.5)"
        fontSize={{ xs: "60px", md: "100px" }}
        mb={{ xs: "-0.75rem", lg: "0px" }}
      >
        {number}
      </Typography>
      <Typography variant="h4" mb="1rem" fontSize={{ xs: "24px", md: "28px" }}>
        {title}
      </Typography>
      <Typography variant="body2" color="white" fontWeight="400" fontFamily="Montserrat" lineHeight="24px" textAlign={"justify"} maxWidth={420}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default SeoItem;
