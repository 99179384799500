import { Typography, Box, Container, Divider, List, ListItem, ListItemText, ListItemIcon, Stack } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import GroupsIcon from "@mui/icons-material/Groups";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditNoteIcon from "@mui/icons-material/EditNote";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const styles = {
  root: {
    backgroundColor: "primary.main",
    color: "#fff",
    borderRadius: "2rem",
    padding: "4rem 0",
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      left: "-1rem",
      bottom: "20%",
      width: "2.5rem",
      display: { xs: "none", sm: "inline" },
      height: { xs: "50rem", md: "30rem" },
      backgroundColor: "background.default",
      borderTopRightRadius: "100px",
      borderBottomRightRadius: "100px",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      right: "-1rem",
      bottom: "20%",
      width: "2.5rem",
      display: { xs: "none", sm: "inline" },
      height: { xs: "50rem", md: "30rem" },
      backgroundColor: "background.default",
      borderTopLeftRadius: "100px",
      borderBottomLeftRadius: "100px",
    },
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: "2rem",
    paddingBottom: "3rem",
  },
};
export const SatisfactionGuarantee = () => {
  return (
    <Container maxWidth="xxl">
      <Box sx={styles.root}>
        <Container maxWidth="xl">
          <Box sx={styles.top}>
            <Typography variant="h3" component="h2" fontWeight="bold">
              Fast, Friendly,
              <br /> and Satisfaction
              <br /> Guarantee
            </Typography>
            <Typography maxWidth="31rem" sx={{ wordSpacing: "0.5rem" }}>
              We prioritize rapid development, offer a friendly and collaborative approach, and stand by our work with a guarantee of your complete
              satisfaction. Elevate your online presence with a website that is not only efficient but also reflects our dedication to a positive and
              client-centered design experience.
            </Typography>
          </Box>
          <Divider color="#fff" />
          <List>
            <Stack direction="row" justifyContent="space-around" marginTop="2rem" flexWrap="wrap">
              <Box>
                <ListItem sx={{ maxWidth: "30rem" }}>
                  <ListItemIcon>
                    <VerifiedIcon sx={{ color: "#fff", fontSize: { xs: "3rem", sm: "4rem" }, marginRight: "2rem" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6" fontFamily="Montserrat" gutterBottom>
                        Satisfaction Guarantee
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption">
                        We stand by our service with a satisfaction guarantee, providing confidence that your website will meet or exceed your
                        expectations.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem sx={{ maxWidth: "30rem" }}>
                  <ListItemIcon>
                    <GroupsIcon sx={{ color: "#fff", fontSize: { xs: "3rem", sm: "4rem" }, marginRight: "2rem" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6" fontFamily="Montserrat" gutterBottom>
                        Supportive Team
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption">
                        Experience a customer-centric approach with our friendly support team, ready to assist you at every stage of website creation,
                        offering guidance and addressing any queries.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem sx={{ maxWidth: "30rem" }}>
                  <ListItemIcon>
                    <CalendarMonthIcon sx={{ color: "#fff", fontSize: { xs: "3rem", sm: "4rem" }, marginRight: "2rem" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6" fontFamily="Montserrat" gutterBottom>
                        Flexible Appointments
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption">
                        Enjoy the convenience of appointments tailored to your schedule, ensuring a hassle-free and accommodating experience.
                      </Typography>
                    }
                  />
                </ListItem>
              </Box>
              <Box>
                <ListItem sx={{ maxWidth: "30rem" }}>
                  <ListItemIcon>
                    <EditNoteIcon sx={{ color: "#fff", fontSize: { xs: "3rem", sm: "4rem" }, marginRight: "2rem" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6" fontFamily="Montserrat" gutterBottom>
                        Efficient Website Construction
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption">
                        Our service ensures a rapid and streamlined website building process, minimizing time-to-launch and simplifying the
                        development stages.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem sx={{ maxWidth: "30rem" }}>
                  <ListItemIcon>
                    <FavoriteIcon sx={{ color: "#fff", fontSize: { xs: "3rem", sm: "4rem" }, marginRight: "2rem" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6" fontFamily="Montserrat" gutterBottom>
                        User-Friendly Design:
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption">
                        Navigate the website construction process effortlessly with our user-friendly interface, designed to make the entire procedure
                        clear and accessible.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem sx={{ maxWidth: "30rem" }}>
                  <ListItemIcon>
                    <CreditCardIcon sx={{ color: "#fff", fontSize: { xs: "3rem", sm: "4rem" }, marginRight: "2rem" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6" fontFamily="Montserrat" gutterBottom>
                        Transparent Pricing:
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption">
                        Benefit from clear and transparent pricing, ensuring you understand the costs involved in our website building service.
                      </Typography>
                    }
                  />
                </ListItem>
              </Box>
            </Stack>
          </List>
        </Container>
      </Box>
    </Container>
  );
};
