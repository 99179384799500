import React from "react";
import { Typography, useTheme, Grid, Box, styled, Container } from "@mui/material";
import bannerImg from "../../assets/images/people-taking-part-business-event.png";
import MemberCard from "./components/MemberCard";
import groupImg from "../../assets/images/members/Group.png";
import Bani from "../../assets/images/members/bani.png";
import Ali from "../../assets/images/members/ali.png";
import AmirMostafa from "../../assets/images/members/amir-mostafa.png";
import Amir from "../../assets/images/members/amir.png";
import Bahar from "../../assets/images/members/bahar.png";
import Benyamin from "../../assets/images/members/benyamin.png";
import Maryam from "../../assets/images/members/maryam.png";

function OurTeam(props) {
  const theme = useTheme();
  const styles = {
    topBox: { textAlign: "center", pb: "30px" },
    banner: {
      width: "100%",
      maxHeight: "900px",
      overflow: "hidden",
      objectFit: "cover",
    },
    group: {
      width: "100%",
      overflow: "hidden",
      objectFit: "cover",
    },
  };

  const Item = styled(Box)(() => ({
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    marginTop: "50px",
  }));

  const personData = [
    {
      imageSrc: Bani,
      name: "Bani shirazi",
      role: "founder - full stack developer",
      description: "Bani manages every branding and web design project  down to the details, leading creative team to always express their best.",
    },
    {
      imageSrc: Benyamin,
      name: "Benyamin Naudi",
      role: "front-end developer",
      description: "Benyamin, our Front-End Maestro—turning visions into vibrant, user-centric realities.",
    },
    {
      imageSrc: Ali,
      name: "Ali Asgari",
      role: "designer",
      description: "Ali, our design virtuoso, turning concepts into captivating visuals that speak volumes.",
    },
    {
      imageSrc: Amir,
      name: "amir shahsahebi",
      role: "full-stack developer",
      description: "Amir, our Full Stack Maestro—crafting end-to-end digital excellence with finesse.",
    },
    {
      imageSrc: AmirMostafa,
      name: "Amir mostafa Haji sadeghian",
      role: "full-stack developer",
      description: "Amir, our Full Stack Maestro—crafting dynamic and cohesive web experiences from front-end to back-end.",
    },
    {
      imageSrc: Maryam,
      name: "Maryam Sharifi",
      role: "Business analyst",
      description: "Maryam, our Business Insight Architect—transforming data into strategic brilliance for your success.",
    },
    {
      imageSrc: Bahar,
      name: "Bahar Hekmat Mehr",
      role: "Scrum master",
      description: "Bahar, our Agile Maestro—streamlining teamwork for project success.",
    },
  ];

  return (
    <>
      <Box>
        <Box sx={styles.topBox}>
          <Typography variant="h3">Crafting Tomorrow's Web, Building</Typography>
          <Typography variant="h2">APSLegacy Today</Typography>
        </Box>
        <Box component="img" alt="design-img" src={bannerImg} sx={styles.banner} />
        <Box>
          <Typography variant="h3" textAlign={"center"} padding={3} color={"rgba(34, 0, 35, 1)"}>
            OUR TEAM
          </Typography>
        </Box>
        <Grid container sx={{ backgroundColor: "rgba(22, 0, 23, 1)", padding: "50px 0", alignItems: "flex-end" }}>
          <Grid item lg={12} xs={12}>
            <Item>
              <MemberCard
                imageSrc={personData[0].imageSrc}
                name={personData[0].name}
                description={personData[0].description}
                role={personData[0].role}
              />
            </Item>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Item>
              <MemberCard
                imageSrc={personData[1].imageSrc}
                name={personData[1].name}
                description={personData[1].description}
                role={personData[1].role}
              />
            </Item>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Item>
              <MemberCard
                imageSrc={personData[2].imageSrc}
                name={personData[2].name}
                description={personData[2].description}
                role={personData[2].role}
              />
            </Item>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Item>
              <MemberCard
                imageSrc={personData[3].imageSrc}
                name={personData[3].name}
                description={personData[3].description}
                role={personData[3].role}
              />
            </Item>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Item>
              <MemberCard
                imageSrc={personData[4].imageSrc}
                name={personData[4].name}
                description={personData[4].description}
                role={personData[4].role}
              />
            </Item>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Item>
              <MemberCard
                imageSrc={personData[5].imageSrc}
                name={personData[5].name}
                description={personData[5].description}
                role={personData[5].role}
              />
            </Item>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Item>
              <MemberCard
                imageSrc={personData[6].imageSrc}
                name={personData[6].name}
                description={personData[6].description}
                role={personData[6].role}
              />
            </Item>
          </Grid>
        </Grid>
        <Container>
          <Typography
            variant="h3"
            fontWeight={"700"}
            letterSpacing={2}
            textTransform={"uppercase"}
            padding={"50px 0 10px 0"}
            color={"rgba(0, 0, 0, 1)"}
          >
            Our Collective Brilliance:
            <br />A Unified Force
          </Typography>
          <Typography padding={"0 0 50px 0"} color={"rgba(0, 0, 0, 1)"}>
            In our dynamic community, diverse talents converge, creating a synergy that propels us towards excellence. Together, we navigate
            challenges, transform ideas, and celebrate collective triumphs. This is more than a team; it's a vibrant community where every individual
            adds a unique hue to our shared masterpiece of success. Welcome to the nexus of collaboration, innovation, and unified achievement.
          </Typography>
        </Container>
        <Box component="img" alt="design-img" src={groupImg} sx={styles.group} />
        <Box>
          <Typography
            textAlign={"center"}
            letterSpacing={15}
            fontSize={"20vw"}
            fontWeight={"700"}
            textTransform={"uppercase"}
            padding={3}
            color={"rgba(34, 0, 35, 1)"}
          >
            legacy
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default OurTeam;
