import { Typography, Box, Stack } from "@mui/material";
import headingImg from "../../../assets/home/homeHeading.png";
const styles = {
  root: {
    justifyContent: "center",
    padding:"0 1rem"
  },
  container: {
    position: "relative",
  },
  box: {
    position: "absolute",
    right: 0,
    top: { xs: "0", sm: ".5rem", md: "1.5rem" },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      border: "gray 1px solid",
      width: { sm: "60px", md: "120px", lg: "150px" },
      display: { xs: "none", sm: "inline" },
    },
  },
  img: {
    width: { xs: "90px", sm: "170px", md: "240px", xl: "270px" },
  },
  text: {
    fontSize: { xs: "1.1rem", sm: "2.5rem", md: "4rem", xl: "5rem" },
    letterSpacing: "10px",
  },
};

export const Heading = () => {
  return (
    <Stack direction="row" component="section" sx={styles.root}>
      <Box sx={styles.container}>
        <Typography component="h1" fontFamily="Lemon Milk Font" sx={styles.text}>
          LIMITLESS,
          <br /> ANY TIME OF DAY.
        </Typography>
        <Box sx={styles.box} component="div">
          <Box component="img" src={headingImg} alt="img" sx={styles.img} />
        </Box>
      </Box>
    </Stack>
  );
};
