import { Box, Container, Stack } from "@mui/material";
import ServiceCard from "./ServiceCard";

const dataServices = [
  {
    id: 1,
    title: "How to build an online business website",
    content:
      "Building an online business website can seem like a daunting task, but with the right tools and knowledge, anyone can create a professional and effective website. In this article, we will discuss the steps you need to take to build an online business website.",
  },
  {
    id: 2,
    title: "10 Essential Elements of a Successful Business Website",
    content:
      "In today’s fast-paced digital landscape, establishing a robust online footprint stands as an imperative for business success. Your website often acts as the initial touchpoint for prospective clients, assuming a pivotal role in molding their perception of your brand.",
  },
  {
    id: 3,
    title: "What is SEO? Why Is It Important? All the Reasons Why Your Business Needs SEO!",
    content:
      "In today’s digital era, where the internet serves as a virtual marketplace for businesses, it has become crucial to stand out from the competition. One of the most effective ways to achieve this is by leveraging Search Engine Optimization (SEO).",
  },
  {
    id: 4,
    title: "Summer 2023 Online Shopping Trends to Help You Start Your Business This Year",
    content:
      "The world of business e-commerce is rapidly evolving, and staying ahead of the curve is essential for entrepreneurs aiming to carve a successful niche. With the summer of 2023 winding down, it’s the perfect time to explore the top online shopping trends that are poised to shape the business landscape.",
  },
];

export const ServiceSection3 = () => {
  return (
    <Box py={{ xs: 10, md: 20 }}>
      <Container>
        <Stack direction={{ xs: "column", md: "row" }} pl={{ md: "3rem", lg: "0" }} flexWrap="wrap">
          {dataServices.map((service) => (
            <ServiceCard service={service} key={service.id} />
          ))}
        </Stack>
      </Container>
    </Box>
  );
};
