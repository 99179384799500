import { Box, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";

const slideShow = keyframes`
    100% {
      transform: translateX(-2000px);
    }
`;

const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "primary.main",
    color: "#FFF",
    padding: "1rem 0",
    width: "4000px",
    animation: `${slideShow} 50s linear infinite`,
  },
  boxes: {
    display: "flex",
    alignItems: "center",
  },
};
export const Slider = () => {
  return (
    <Box sx={{ overflow: "clip" }}>
      <Box sx={styles.root}>
        <Box sx={styles.boxes}>
          <Typography variant="h3">ui/ux</Typography>
        </Box>
        _____________________
        <Box sx={styles.boxes}>
          <Typography variant="h3">Graphic design</Typography>
        </Box>
        _____________________
        <Box sx={styles.boxes}>
          <Typography variant="h3">Branding</Typography>
        </Box>
        _____________________
        <Box sx={styles.boxes}>
          <Typography variant="h3">ui/ux</Typography>
        </Box>
        _____________________
        <Box sx={styles.boxes}>
          <Typography variant="h3">Graphic design</Typography>
        </Box>
        _____________________
        <Box sx={styles.boxes}>
          <Typography variant="h3">Branding</Typography>
        </Box>
        _____________________
      </Box>
    </Box>
  );
};
