import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Route, Routes, useLocation } from "react-router-dom";
// import App from "./App";
import Footer from "./components/footer";
import Header from "./components/header";
import "./index.css";
import About from "./pages/about/About";
import BuildWebsite from "./pages/build-website/BuildWebsite";
import Seo from "./pages/seo/Seo";
import Design from "./pages/design/Design";
import Home from "./pages/home/Home";
import Pricing from "./pages/pricing/Pricing";
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "./utils/theme";
import CssBaseline from "@mui/material/CssBaseline";
import OurTeam from "./pages/our-team/OurTeam";
import Services from "./pages/services/Services";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ThemeProvider>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/design" element={<Design />} />
          <Route path="/build-website" element={<BuildWebsite />} />
          <Route path="/seo" element={<Seo />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/services" element={<Services />}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
);
