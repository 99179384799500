import { Typography, Container, List, ListItem, ListItemText, Collapse, Box } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const styles = {
  heading: {
    textAlign: "center",
    marginTop: { xs: "4rem", md: "7rem" },
  },
  textJustify: {
    textAlign: "justify",
    textAlignLast: "center",
  },
  listItem: {
    margin: "1rem 0",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F3F5F9",
    },
  },
};

export const Question = () => {
  const [openCollapse, setOpenCollapse] = useState(false);

  function clickHandler(e) {
    setOpenCollapse(openCollapse === e.target.id ? false : e.target.id);
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h3" fontWeight="bold" sx={styles.heading} gutterBottom>
        Frequently Asked
        <br />
        <Box sx={{ color: "#6D336D" }} component="span">
          Questions
        </Box>{" "}
      </Typography>

      <Typography variant="body2" sx={styles.textJustify}>
        We understand that navigating through the details of our offerings can sometimes raise questions. This curated compilation of frequently asked
        questions is designed to provide quick and clear answers to address your uncertainties. you'll find concise and informative responses here. At
        APSlegacy, our commitment to transparency extends to this FAQ section, ensuring you have easy access to the information you need to make
        informed decisions. Browse through our FAQs to gain a deeper understanding of our work process and discover the solutions that best fit your
        goals.
      </Typography>
      <List sx={{ marginTop: "3rem" }}>
        <Box sx={styles.listItem} onClick={clickHandler}>
          <ListItem id="11">
            <ListItemText>
              <Typography variant="h6" fontWeight="bold" id="11">
                What is Legacy?
              </Typography>{" "}
            </ListItemText>
            {openCollapse === "11" ? <ExpandLess id="11" /> : <ExpandMore id="11" />}
          </ListItem>
          <Collapse in={openCollapse === "11"} timeout="auto">
            <Typography variant="body2" padding="0 1rem 1rem 1rem" id="11">
              APSlegacy is a comprehensive IT solutions provider specializing in website development, SEO, and design services. Dedicated to fostering
              transparency and client empowerment, we leverage our expertise to build strong online presences for our clients. From crafting visually
              appealing designs to implementing effective SEO strategies, APSlegacy is committed to delivering tailored solutions that drive digital
              success for businesses.
            </Typography>
          </Collapse>
        </Box>

        <Box sx={styles.listItem} onClick={clickHandler}>
          <ListItem sx={styles.listItem} id="21">
            <ListItemText>
              <Typography variant="h6" fontWeight="bold" id="21">
                Are the service providers on Legacy reliable and qualified?
              </Typography>{" "}
            </ListItemText>
            {openCollapse === "21" ? <ExpandLess id="21" /> : <ExpandMore id="21" />}
          </ListItem>
          <Collapse in={openCollapse === "21"} timeout="auto">
            <Typography variant="body2" padding="0 1rem 1rem 1rem" id="21">
              At APSlegacy, we take immense pride in the reliability and qualifications of our service providers. Our team consists of highly skilled
              professionals with extensive experience in website development, SEO, and design. We meticulously vet and select individuals who not only
              possess technical expertise but also share our commitment to delivering exceptional results. Our service providers undergo rigorous
              training to stay abreast of industry trends and emerging technologies, ensuring that they can meet the diverse needs of our clients.
              Rest assured, when you choose APSlegacy, you are partnering with a team of reliable and qualified experts dedicated to bringing your
              digital vision to life.
            </Typography>
          </Collapse>
        </Box>

        <Box sx={styles.listItem} onClick={clickHandler}>
          <ListItem sx={styles.listItem} id="31">
            <ListItemText>
              <Typography variant="h6" fontWeight="bold" id="31">
                What should I do if I'm unhappy with a service provider?
              </Typography>{" "}
            </ListItemText>

            {openCollapse === "31" ? <ExpandLess id="31" /> : <ExpandMore id="31" />}
          </ListItem>
          <Collapse in={openCollapse === "31"} timeout="auto">
            <Typography variant="body2" padding="0 1rem 1rem 1rem" id="31">
              At APSlegacy, we value open communication and strive to address any concerns promptly. If you encounter an issue or have a complaint
              about a service provider, we encourage you to reach out to our dedicated customer support team. You can contact us through our
              designated channels, and we will work diligently to understand the nature of your concern. Our commitment is to resolve issues swiftly,
              ensuring your satisfaction and maintaining the high standards we set for our services. Your feedback is essential to us, and we
              appreciate the opportunity to continuously improve and enhance your experience with APSlegacy.
            </Typography>
          </Collapse>
        </Box>

        <Box sx={styles.listItem} onClick={clickHandler}>
          <ListItem sx={styles.listItem} id="41">
            <ListItemText>
              <Typography variant="h6" fontWeight="bold" id="41">
                How are payments handled on Legacy?
              </Typography>{" "}
            </ListItemText>

            {openCollapse === "41" ? <ExpandLess id="41" /> : <ExpandMore id="41" />}
          </ListItem>
          <Collapse in={openCollapse === "41"} timeout="auto">
            <Typography variant="body2" padding="0 1rem 1rem 1rem" id="41">
              At Legacy, we've designed a straightforward and client-friendly payment process to ensure transparency and customer satisfaction. To
              initiate a project, we require an initial payment of 50% of the total cost. This payment not only serves as an approval to commence the
              work but also demonstrates our commitment to delivering high-quality services. Once the project is completed to your satisfaction, the
              remaining 50% can be paid. We prioritize your contentment, and if, for any reason, you are not satisfied with the delivered work, we
              offer a 100% money-back guarantee. Additionally, we understand the importance of flexibility in payments. For your convenience, we
              provide installment payment options within specified conditions, allowing you to manage your budget effectively. At Legacy, our aim is
              not just to meet but to exceed your expectations, and our payment structure is tailored to reflect our dedication to your satisfaction
              and financial ease.
            </Typography>
          </Collapse>
        </Box>
        <Box sx={styles.listItem} onClick={clickHandler}>
          <ListItem sx={styles.listItem} id="51">
            <ListItemText>
              <Typography variant="h6" fontWeight="bold" id="51">
                Can I ask for changes to the plan while the project is in progress?
              </Typography>{" "}
            </ListItemText>

            {openCollapse === "51" ? <ExpandLess id="51" /> : <ExpandMore id="51" />}
          </ListItem>
          <Collapse in={openCollapse === "51"} timeout="auto">
            <Typography variant="body2" padding="0 1rem 1rem 1rem" id="51">
              Yes, at APSlegacy, we highly value collaboration and understand that refining the design to meet your expectations is crucial. During
              the project, you can provide feedback and request changes, and we offer the flexibility for one or two rounds of revisions at no
              additional cost. This guarantees that the final product completely reflects the idea that you had. It's important to note that we
              discuss and explain the exact costs associated with additional changes in our initial meetings, providing transparency and clarity
              regarding any potential extra charges. We believe in open communication and work closely with you to accommodate your needs within the
              agreed-upon scope of the project. Our goal is to deliver a design that not only meets but exceeds your expectations, providing you with
              the best possible digital representation of your brand.
            </Typography>
          </Collapse>
        </Box>
      </List>
    </Container>
  );
};
