import { Stack, Typography } from "@mui/material";

const ServiceCard = ({ service }) => {
  return (
    <Stack
      minWidth="50%"
      flex={1}
      px={{ xs: 2, md: 10 }}
      position="relative"
      mb={{ xs: 3, md: 0 }}
      mt={{ xs: 3, md: service.id === 2 || service.id === 4 ? "6rem" : "0" }}
    >
      <Typography
        position={{ xs: "static", md: "absolute" }}
        left={service.id === 1 ? "-2%" : "-4%"}
        top={service.id === 2 || service.id === 4 ? "-17%" : "-13%"}
        variant="caption"
        fontSize={{ xs: "75px", md: "120px" }}
        color="#A3ACB1"
      >
        {service.id}
      </Typography>
      <Typography variant="h5" fontWeight="bold" lineHeight="35px">
        {service.title}
      </Typography>
      <Typography variant="subtitle2" mt={3} lineHeight="25px" textAlign="justify" fontSize={{ xs: 12, md: 16 }}>
        {service.content}
      </Typography>
    </Stack>
  );
};

export default ServiceCard;
