import { Typography, Box, Divider, Grid, Container, Stack } from "@mui/material";
import SeoItem from "./SeoItems";
import SeoChallangeImage from "../../../../assets/ceo-seo-challenges.png";

const styles = {
  root: {
    bgcolor: "#220023",
    color: "#fff ",
    p: "65px",
  },
  HeadingText: {
    fontWeight: 400,
    fontSize: "16px",
    marginBottom: 2,
    textTransform: "uppercase",
    "@media (max-width: 600px)": {
      fontSize: "1rem",
      letterSpacing: "0",
      lineHeight: "6px",
    },
  },
  HeadingTextTwo: {
    fontWeight: 500,
    marginBottom: 1,
    textTransform: "uppercase",
    "@media (max-width: 600px)": {
      fontSize: "1.3rem",
      letterSpacing: "0",
      lineHeight: "32px",
    },
  },
  Devider: {
    borderColor: "#fff",
    borderWidth: 1,
    width: { xs: "40%", md: "20%" },
    mt: 1,
  },
  Image: {
    Box: {
      zIndex: 1,
      position: "relative",
      textAlign: "start",
      width: "400px",
      height: "405px",
      bgcolor: "#fff",
      margin: "0px auto",
    },
    BoxBorder: {
      position: "absolute",
      zIndex: -5,
      content: '""',
      bottom: "20px",
      right: "20px",
      width: "100%",
      height: "100%",
    },
    image: {
      width: "400px",
      height: "405px",
      display: "block",
      objectFit: "cover",
    },
  },
};

const SeoItems = [
  {
    number: 1,
    title: "Low Website Ranking:",
    subtitle: "Our proven SEO strategies will increase your online presence.",
  },
  {
    number: 2,
    title: "Falling Behind Competitors:",
    subtitle: "Our team of experts will conduct a thorough competitor analysis to identify their strengths and weaknesses.",
  },
  {
    number: 3,
    title: "High Bounce Rate:",
    subtitle: "Minimize bounce rates with our expert SEO strategies tailored to your specific needs.",
  },
];

export const SeoChallangeSection = () => {
  return (
    <Box py={{ xs: 12, lg: 20 }} bgcolor="primary.main" color="white">
      <Container sx={{ marginBottom: { xs: 10, lg: 16 } }}>
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="subtitle1" fontSize={{ xs: "16px", md: "24px" }} textTransform="uppercase" mb="4px">
            SEO Challenges:
          </Typography>
          <Typography variant="h3" textTransform="uppercase" fontSize={{ xs: "28px", md: "50px" }}>
            Boost Your Website Ranking
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Divider orientation="horizontal" variant="middle" sx={styles.Devider} />
          </Box>
        </Stack>
      </Container>
      <Container>
        <Grid container direction={{ xs: "column-reverse", lg: "row" }} sx={{ justifyContent: "center", alignItems: "center" }}>
          <Grid item xs={12} lg={6}>
            <Stack direction="column" spacing={{ xs: 5, lg: 14 }}>
              {SeoItems.map((item, index) => (
                <SeoItem key={index} number={item.number} title={item.title} subtitle={item.subtitle} />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} lg={6} mb={{ xs: 6, lg: 0 }} sx={{ justifyContent: "center" }}>
            <Box
              component="img"
              src={SeoChallangeImage}
              alt="seo-challenge-image"
              boxShadow="16px 16px white"
              marginLeft={{ xs: "-1rem", md: "2rem" }}
              width={{ xs: "255px", md: "400px" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
