import { Box, Container, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const ServiceSection2 = () => {
  return (
    <Stack bgcolor="primary.main" pt={{ xs: 18, md: 24 }} pb={16} alignItems="center" color="white">
      <Typography variant="h3" letterSpacing={{ xs: 3, md: 6 }} px={{ xs: 2, md: 0 }} fontWeight="bold" fontSize={{ xs: "24px", md: "3rem" }}>
        BUILD WEBSITE PROCESS
      </Typography>
      <Box width={{ xs: "40%", md: "20%" }} height="2px" bgcolor="white" mt={2} alignSelf={{ xs: "start", sm: "center" }} ml={{ xs: 2, sm: 0 }}></Box>
      <Container>
        <Typography variant="body1" mt={6} lineHeight="28px" textAlign="justify" fontSize={{ xs: 14, md: 16 }}>
          Leap ahead of your competitors and bring a fresh new look and appeal to the world of business with our team of experts! <br />
          With our team of experts, you can confidently leap ahead of your competitors and make a lasting impact in the world of business. We are
          dedicated to helping you bring a fresh new look and appeal to your brand, ensuring that you stand out from the crowd. Our experience and
          expertise will enable you to navigate the ever-changing business landscape and achieve success. Trust us to deliver innovative solutions
          that will elevate your business to new heights. The build website process also involves collaboration between our inventors, strategists,
          and controversy. This ensures that your followership has a picture-perfect experience through your website’s structurally-solid armature.
        </Typography>
      </Container>
      {/* <Stack justifyContent={"center"}>
        <Link style={{ color: "#fff", position: "relative", width: "200px", marginBottom: "50px" }} to={"/seo"}>
          <Typography
            variant="body1"
            mt={10}
            mb={{ xs: -4, md: 0 }}
            fontWeight="bold"
            fontSize="18px"
            component="span"
            sx={{
              transition: "all 0.3s",
              position: "absolute",
              "&::after": {
                content: '""',
                width: "100%",
                height: "2px",
                position: "absolute",
                bottom: 0,
                left: 0,
                backgroundColor: "#fff",
                transform: "scaleX(0.5)",
                transformOrigin: "bottom left",
                transition: "transform 0.25s ease-out",
              },
              "&:hover::after": {
                transform: "scaleX(1)",
                height: "2.5px",
                transformOrigin: "bottom left",
              },
            }}
          >
            BOOK A MEETING
          </Typography>
        </Link>
      </Stack> */}
    </Stack>
  );
};
