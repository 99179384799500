import { Typography, Container, List, ListItem, ListItemIcon, ListItemText, Stack, Box } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
const styles = {
  root: {
    padding: "4rem 0",
  },
  para: {
    maxWidth: "57rem",
  },
  listItem: {
    color: "#6D336D",
  },
  list: {
    marginTop: "1.5rem",
    flexDirection: { xs: "column", sm: "row" },
    gap: { sm: "5rem" },
  },
};

const Proficiency = () => {
  return (
    <Container sx={styles.root}>
      <Typography fontWeight="bold" variant="h3" component="h3" gutterBottom padding="0 1rem">
        Proficiency in Website Development
      </Typography>
      <Typography component="p" variant="body1" sx={styles.para} gutterBottom padding="1rem 1rem 0 1rem">
        You need help for your website? Choose us for website development that goes beyond the basics, offering a blend of innovation, functionality,
        and security to elevate your online presence.
      </Typography>

      <List>
        <Stack sx={styles.list}>
          <Box>
            {" "}
            <ListItem sx={styles.listItem}>
              <ListItemIcon>
                <DoneIcon sx={styles.listItem} />
              </ListItemIcon>
              <ListItemText>Expertise in Diverse Technologies</ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemIcon>
                <DoneIcon sx={styles.listItem} />
              </ListItemIcon>
              <ListItemText>Customization Tailored to Your Brand</ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemIcon>
                <DoneIcon sx={styles.listItem} />
              </ListItemIcon>
              <ListItemText>Optimized Performance and Speed</ListItemText>
            </ListItem>
          </Box>
          <Box>
            {" "}
            <ListItem sx={styles.listItem}>
              <ListItemIcon>
                <DoneIcon sx={styles.listItem} />
              </ListItemIcon>
              <ListItemText>Responsive Design Mastery</ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemIcon>
                <DoneIcon sx={styles.listItem} />
              </ListItemIcon>
              <ListItemText>E-commerce Excellence</ListItemText>
            </ListItem>
            <ListItem sx={styles.listItem}>
              <ListItemIcon>
                <DoneIcon sx={styles.listItem} />
              </ListItemIcon>
              <ListItemText>Robust Security Measures</ListItemText>
            </ListItem>
          </Box>
        </Stack>
      </List>
    </Container>
  );
};

export default Proficiency;
