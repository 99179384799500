import { Typography, Container, Stack, Box } from "@mui/material";
import fImg from "../../../assets/home/Frame 149.png";
import sImg from "../../../assets/home/Frame 151.png";
import tImg from "../../../assets/home/Frame 153.png";
import { Link } from "react-router-dom";

const styles = {
  root: { paddingTop: "5rem" },
  text1: {
    color: "#B9B9B9B9",
    fontSize: { xs: "13px", sm: "18px", md: "22px" },
    letterSpacing: "3px",
  },
  text2: {
    letterSpacing: ".3rem",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      display: { xs: "none", sm: "inline" },
      left: { xs: "20rem", sm: "24rem", md: "27rem" },
      border: "gray 1px solid",
      width: { xs: "60px", md: "100px" },
    },
  },
  heading: {
    letterSpacing: ".3rem",
  },
  para: {
    maxWidth: "30rem",
    lineHeight: "1.8rem",
  },
  top: {
    marginBottom: "3rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: { xs: "center", lg: "space-between" },
    alignItems: "center",
    gap: "4rem",
  },
  boxes: {
    display: "flex",
    gap: "2rem 8rem ",
    padding: "0 1rem",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: { xs: "center", lg: "start" },
  },
  underlineAnimation: {
    transition: "all 0.3s",
    position: "absolute",
    "&::after": {
      content: '""',
      width: "100%",
      height: "2px",
      position: "absolute",
      bottom: 0,
      left: 0,
      backgroundColor: "black",
      transform: "scaleX(0.5)",
      transformOrigin: "bottom left",
      transition: "transform 0.25s ease-out",
    },
    "&:hover::after": {
      transform: "scaleX(1)",
      height: "2.5px",
      transformOrigin: "bottom left",
    },
  },
};
export const OurServices = () => {
  return (
    <Container sx={styles.root}>
      <Box sx={styles.top}>
        <Stack>
          <Typography component="h2" fontFamily="Lemon Milk Font" sx={styles.text1} gutterBottom>
            OUR SERVICES
          </Typography>
          <Typography component="h3" variant="h3" fontWeight={"bold"} sx={styles.text2} gutterBottom>
            WHAT WE DO ?
          </Typography>
          <Typography component="p" variant="body1" sx={styles.para}>
            At APSLegacy, we believe that digital marketing is essential for any business that wants to succeed in today's competitive online
            landscape.
          </Typography>
        </Stack>
        <Stack>
          <Typography component="p" gutterBottom variant="body1" sx={styles.para}>
            That's why we offer a wide range of digital marketing services, including UI/UX design, content marketing, SEO, and more.
          </Typography>
          <Link style={{ color: "#000" }} to={"/design"}>
            <Typography component="span" sx={styles.underlineAnimation}>
              FREE GUIDE
            </Typography>
          </Link>
        </Stack>
      </Box>

      <Stack gap="7rem">
        <Box sx={styles.boxes}>
          <Box component="img" sx={{ boxShadow: "-17px -17px 0px #220023" }} maxWidth="100%" src={fImg} />
          <Stack>
            <Typography component="h3" variant="h3" sx={styles.heading} gutterBottom>
              UI/UX
              <Box
                component="span"
                sx={{ color: "#FFFDF5", textShadow: "-1px -1px 0 #B9B9B9B9, 1px -1px 0 #B9B9B9B9, -1px 1px 0 #B9B9B9B9, 1px 1px 0 #B9B9B9B9" }}
              >
                UI/UX
              </Box>
            </Typography>
            <Typography component="p" variant="body1" sx={styles.para} gutterBottom>
              Our team of experienced and certified UI/UX designers will work with you to create a design that is both visually appealing and easy to
              use. We will also conduct user testing to ensure that your design is user-friendly and meets the needs of your target audience.
            </Typography>
            <Link style={{ color: "#000" }} to={"/design"}>
              <Typography component="span" sx={styles.underlineAnimation}>
                MORE
              </Typography>
            </Link>
          </Stack>
        </Box>
        <Box sx={styles.boxes} alignSelf="end">
          <Box component="img" maxWidth="100%" sx={{ boxShadow: "17px -17px 0px #220023" }} src={sImg} />
          <Stack>
            <Typography component="h3" variant="h3" sx={styles.heading} gutterBottom>
              SEO
              <Box
                component="span"
                sx={{ color: "#FFFDF5", textShadow: "-1px -1px 0 #B9B9B9B9, 1px -1px 0 #B9B9B9B9, -1px 1px 0 #B9B9B9B9, 1px 1px 0 #B9B9B9B9" }}
              >
                SEO
              </Box>
            </Typography>
            <Typography component="p" variant="body1" sx={styles.para} gutterBottom>
              APSLegacy SEO service is designed to help you improve your website's visibility in search engine results pages (SERPs), so that you can
              reach more potential customers and grow your business. We understand that SEO is a complex and ever-changing field, so we stay
              up-to-date on the latest trends and best practices to ensure that your SEO campaigns are successful.
            </Typography>
            <Link style={{ color: "#000" }} to={"/seo"}>
              <Typography component="span" sx={styles.underlineAnimation}>
                MORE
              </Typography>
            </Link>
          </Stack>
        </Box>
        <Box sx={styles.boxes}>
          <Box component="img" maxWidth="100%" sx={{ boxShadow: "-17px 17px 0px #220023" }} src={tImg} />
          <Stack>
            <Typography component="h3" variant="h3" sx={styles.heading} gutterBottom>
              CONTENT
              <Box
                component="span"
                sx={{ color: "#FFFDF5", textShadow: "-1px -1px 0 #B9B9B9B9, 1px -1px 0 #B9B9B9B9, -1px 1px 0 #B9B9B9B9, 1px 1px 0 #B9B9B9B9" }}
              >
                CONTENT
              </Box>
            </Typography>
            <Typography component="p" variant="body1" sx={styles.para} gutterBottom>
              We believe that content is a powerful tool that can help businesses achieve their marketing and communication goals. That's why we are
              committed to providing our clients with the highest quality content services possible.
            </Typography>
            {/* <Link style={{ color: "#000" }} to={"/seo"}>
              <Typography component="span" sx={styles.underlineAnimation}>
                MORE
              </Typography>
            </Link> */}
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};
