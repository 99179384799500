import { Typography, Box, Container, Stack, TextField, Button } from "@mui/material";

const styles = {
  formBox: {
    border: "1px solid #000",
    padding: "2rem",
    backgroundColor: "#fff",
    boxShadow: "18px 18px #fff, 18px 18px 0 1px #000",
    margin: "0 1rem",
  },
};
export const Form = () => {
  return (
    <Container sx={{ marginTop: "7rem", marginBottom: "10rem" }}>
      <Stack direction="row" flexWrap="wrap" justifyContent="space-around" gap="2rem">
        <Box>
          <Typography variant="h3" fontWeight="bold" gutterBottom>
            Fill the Form
            <br /> to{" "}
            <Box component="span" sx={{ color: "#6D336D" }}>
              get a free
              <br /> consultation
            </Box>
          </Typography>
          <Typography maxWidth="28rem">
            Ready to elevate your IT experience? Fill out our form for a free consultation and let's discuss how our tailored IT services can propel
            your business forward. Trust us to understand your unique needs and deliver solutions that drive success in the digital era. Choose [Your
            Company Name] for expert guidance and cutting-edge solutions – your path to a seamless and optimized IT future starts here.
          </Typography>
        </Box>

        <Box sx={styles.formBox}>
          <form action="">
            <Stack direction="row" gap="2rem" marginBottom="1rem">
              <TextField variant="standard" label="Full name" />
              <TextField variant="standard" label="Email" type="email" />
            </Stack>
            <Stack direction="row" gap="2rem" marginBottom="1rem">
              <TextField variant="standard" label="Telephone" type="tel" />
              <TextField variant="standard" label="Service" />
            </Stack>

            <TextField variant="standard" fullWidth multiline label="Message" rows={4} />

            <Button fullWidth variant="contained" type="submit" sx={{ marginTop: "2rem" }}>
              Get Strated
            </Button>
          </form>
        </Box>
      </Stack>
    </Container>
  );
};
