import { Typography, Box, Container } from "@mui/material";
import hServices from "../../../assets/services/people-taking-part-business-event.jpg";

const styles = {
  hs1: {
    textAlign: { xs: "center", md: "left" },
    fontSize: { xs: "1.5rem", sm: "2.2rem", md: "2.8rem", lg: "3.3rem" },
    letterSpacing: "8px",
    paddingX: 5,
  },
  hs2: {
    fontSize: { xs: "0.8rem", sm: "1.4rem", md: "2rem", lg: "2.5rem" },
  },
  imgBox: {
    marginTop: { xs: "1rem", md: "2rem" },
    textAlign: "center",
    maxWidth: "100%",
    height: "auto",
    backgroundColor: "primary.main",
  },
  img: {
    width: "100%",
  },
};

export const Heading = () => {
  return (
    <>
      <Container>
        <Typography component="h1" sx={styles.hs1} fontFamily="Lemon Milk Font">
          <Box sx={styles.hs2} component="span">
            Elevate Your Business with
          </Box>
          <br /> Seamless IT Solutions
        </Typography>
      </Container>
      <Box sx={styles.imgBox}>
        <Box component="img" src={hServices} alt="img" sx={styles.img}></Box>
      </Box>
    </>
  );
};
