import React from "react";
import { Heading } from "./components/Heading";
import { Sheading } from "./components/Sheading";
import Proficiency from "./components/Proficiency";
import { Slider } from "./components/Slider";
import { OurServices } from "./components/OurServices";
import { SatisfactionGuarantee } from "./components/SatisfactionGuarantee";
import { Question } from "./components/Questions";
import { Form } from "./components/Form";

function Services() {
  return (
    <>
      <Heading />
      <Sheading />
      <Proficiency />
      <Slider />
      <OurServices />
      <SatisfactionGuarantee />
      <Question />
      <Form />
    </>
  );
}

export default Services;
