import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    border: "1px solid rgba(0, 0, 0)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid rgba(0, 0, 0)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const SeoTable = () => {
  const bodyRows = [
    {
      SEO: [
        "Technical Optimization",
        "Page Audit",
        "Keyword Research",
        "HeadLines(H1,H2,H3)",
        "Title Optimization",
        "Meta Descriptions",
        "Alt Text optimization",
        "Content Optimization",
        "Internal link building",
      ],
      COST: "£500",
    },
    {
      SEO: ["Backlink building"],
      COST: "£250",
    },
  ];

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxWidth: 500,
        boxShadow: "30px 30px 0px -16px rgb(255, 253, 245),30px 30px 0px -15px rgba(0,0,0)",
      }}
    >
      <Table
        //   sx={{ width: 300 }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Typography variant="h6">SEO</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="h6">COST</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell>
              <Typography variant="caption">Technical Optimization</Typography>
            </StyledTableCell>
            <StyledTableCell rowSpan={bodyRows[0].SEO.length}>
              <Typography variant="caption">{bodyRows[0].COST} month</Typography>
            </StyledTableCell>
          </TableRow>
          {bodyRows[0].SEO.slice(1).map((item) => {
            return (
              <TableRow key={item}>
                <StyledTableCell>
                  <Typography variant="caption">{item}</Typography>
                </StyledTableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <StyledTableCell>
              <Typography variant="caption">Backlink building</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="caption">{bodyRows[1].COST}</Typography>
            </StyledTableCell>
          </TableRow>
        </TableBody>
        {/* <TableBody>
          {bodyRows.map((row) => (
            <React.Fragment>
              <StyledTableRow key={row.COST}>
                <StyledTableCell
                  rowSpan={row.SEO.length + 1}
                  //   rowSpan={2}
                  component="td"
                  scope="row"
                >
                  {row.COST}
                </StyledTableCell>
              </StyledTableRow>
              {row.SEO.map((item) => {
                return (
                  <StyledTableRow key={item}>
                    <StyledTableCell key={item} component="td" scope="row">
                      {item}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </React.Fragment>
          ))}
        </TableBody> */}
      </Table>
    </TableContainer>
  );
};

export default SeoTable;
