import { Box, Stack, Divider, Hidden, Typography } from "@mui/material";
import Instagram from "../../../assets/instagramIcon.svg";
import LinkedIn from "../../../assets/linkedInIcon.svg";
import M from "../../../assets/mIcon.svg";

const AboutFooter = () => {
  return (
    <>
      <Typography variant="h3" align="center" sx={{ textTransform: "uppercase", fontWeight: "700" }}>
        get in touch with us!
      </Typography>
      <Stack
        container
        direction="row"
        spacing={7}
        sx={{ padding: "50px 0px", justifyContent: "center", alignItems: "center" }}
        divider={
          <Hidden mdDown>
            <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} style={{ width: "100px", marginRight: "60px", height: "110px" }} />
          </Hidden>
        }
      >
        <Box>
          <a href="https://instagram.com/aps.legacy?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer">
            <Box component="img" src={Instagram} width={{ xs: "35px", md: "52px", lg: "55px" }} />
          </a>
        </Box>
        <Box>
          <a href="https://www.linkedin.com/company/apslegacy/" target="_blank" rel="noreferrer">
            <Box component="img" src={LinkedIn} sx={{ paddingLeft: "34px", width: { xs: "68px", md: "85px", lg: "88px" } }} />
          </a>
        </Box>
        <Box>
          <a href="mailto:info@apslegacy.com" target="_blank" rel="noreferrer">
            <Box component="img" src={M} width={{ xs: "70px", md: "87px", lg: "90px" }} sx={{ paddingLeft: "34px" }} />
          </a>
        </Box>
      </Stack>
    </>
  );
};

export default AboutFooter;
