import { Box, Typography, Container } from "@mui/material";
import React from "react";
import designImg from "../../assets/images/design.png";
import SampleCard from "./SampleCard";
import SampleContentCard from "./SampleContentCard";
import Grid from "@mui/material/Unstable_Grid2";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

function Design(props) {
  const designMainTypes = [
    {
      number: 1,
      title: "GRAPHIC DESIGN",
      description: `
            Graphic design is the creative backbone of visual
            communication on a website. It involves the
            strategic use of images, typography, and colors to
            convey messages and evoke emotions. A well-
            executed graphic design enhances the overall
            aesthetic appeal of a website, making it visually
            engaging and memorable. From logo creation to
            layout design, graphic elements play a crucial role
            in establishing a brand identity and influencing
            user perception. In the digital realm, effective
            graphic design goes beyond aesthetics,
            contributing to a user-friendly interface and
            enhancing the overall user experience.
                `,
    },
    {
      number: 2,
      title: "UI/UX DESIGN",
      description: `
            User Interface (UI) and User Experience (UX) are
            pivotal elements in web design, shaping the digital
            interaction between users and websites. Ul focuses
            on the visual aspects, including layout, colors, and
            interactive components, ensuring a visually
            appealing and intuitive interface. On the other
            hand, UX delves into the overall user journey,
            emphasizing user satisfaction through seamless
            navigation and meaningful interactions. A well-
            crafted UI/UX design enhances accessibility,
            usability, and ultimately, user engagement. Striking
            the right balance between aesthetic appeal and
            functionality is key to creating a website that not
            only captivates users visually but also provides a
            smooth and satisfying browsing experience.
          `,
    },
  ];

  const designContentTypes = [
    {
      title: "LOGO DESIGN",
      description: `
        Our specialized team in logo creation focuses on
        idea generation, creativity, and technical expertise
        to collaborate with you. We strive to create a unique
        and recognizable logo that aligns with your
        company's needs and identity.
        `,
    },
    {
      title: "BRANDING",
      description: `
        Brand building is the process of creating the identity and recognition
        of a product, service, or organization. This process includes creating a
        name, logo, packaging design, and marketing strategy that helps
        distinguish and differentiate the brand from competitors and
        establish a stronger connection with customers.
        `,
    },
    {
      title: "BUSINESS CARD",
      description: `
        Our company specializes in creating visually
        appealing business cards that represent
        your brand's identity, ensuring they leave a
        lasting impression. We carefully select
        colors, fonts, and layouts, and incorporate
        your logo for a memorable experience.
        `,
    },
    {
      title: "PRINT DESIGN",
      description: `
        Print designers specialize in creating visual materials for print production. They
        possess skills in designing various print items such as brochures, posters,
        business cards, flyers, etc. Our designers use formatting, typography, color
        schemes, and attractive and effective images to coordinate the design with
        your brand identity and convey the desired message to your audience.
        `,
    },
    {
      title: "ILLUSTRATION",
      description: `
        Illustration injects vitality into digital content,
        conveying messages through captivating visuals.
        From whimsical characters to informative diagrams,
        illustrations add a unique and memorable touch,
        elevating the overall user experience.
        `,
    },
    {
      title: "PACKAGING DESIGN",
      description: `
        Packaging design blends art and functionality, creating visually
        appealing and informative packaging for products. It goes beyond
        aesthetics, incorporating elements that protect, inform, and attract
        consumers. This design not only reflects the brand but also influences
        purchasing decisions by standing out on the shelf and telling the
        product's story.
        `,
    },
  ];

  return (
    <Box>
      <Container>
        <Box sx={{ ml: { xs: "40px", lg: "60px" }, py: "30px", letterSpacing: "6px" }}>
          <Typography variant="h3" paddingBottom={"3px"}>
            Innovate: Where Ideas Blossom
          </Typography>
          <Typography variant="h2">into Design Wonders!</Typography>
        </Box>
      </Container>
      <Box
        component="img"
        alt="design-img"
        src={designImg}
        sx={{
          width: "100vw",
          maxHeight: "900px",
          //   maxWidth: "1440px",
          overflow: "hidden",
          objectFit: "cover",
        }}
      ></Box>
      <Box sx={{ backgroundColor: "rgb(34, 0, 35)", p: "30px", py: "50px", mt: "-15px" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px", pb: "20px" }}>
          <Typography color={"white"} variant="h3">
            web Design
          </Typography>
          <AutoFixHighIcon sx={{ color: "white", fontSize: "50px" }} />
        </Box>
        <Typography
          variant="body2"
          textAlign={"justify"}
          sx={{ mt: "20px", lineHeight: "30px", px: { lg: "200px", md: "200px", sx: "10px" } }}
          color={"white"}
        >
          Our team specializes in web design, focusing on the careful arrangement of visual elements, layout, and functionality to create an
          attractive and user-friendly website. We prioritize user experience, responsive design, and information architecture to ensure a seamless
          browsing experience. We are committed to supporting you throughout every stage of your website launch.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "start",
          gap: { xs: "30px", md: "30px", lg: "100px" },
          my: "50px",
        }}
      >
        {designMainTypes.map((item) => (
          <SampleCard key={item.number} number={item.number} title={item.title} description={item.description} />
        ))}
      </Box>

      <Container>
        <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ mx: "20px" }}>
          {designContentTypes.map((item, idx) => (
            <SampleContentCard key={item.title} description={item.description} title={item.title} width={item.description.length * 1.5} count={idx} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Design;
