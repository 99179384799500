import { Typography, Box } from '@mui/material';
import { ReactComponent as CeoIcon } from '../../../assets/CeoIcon.svg'; // Import the SVG as a ReactComponent

const styles = {
    root:{
        bgcolor: '#220023',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2
    },
    text:{
        color: '#FFF',
        fontSize: '48px',
        fontStyle: 'bolder',
        fontWeight: 500,
        lineHeight: 'normal',
        display: 'flex',
        alignItems: 'center',
    }
}

export const SeoIconLine = () => {
    return (
        <Box sx={styles.root}>
            <Typography sx={styles.text}>
                SEO <CeoIcon width={'60px'} />
            </Typography>
        </Box>
    )
}  