import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const AboutCard = () => {
  const styles = {
    underlineAnimation: {
      transition: "all 0.3s",
      position: "absolute",
      "&::after": {
        content: '""',
        width: "100%",
        height: "2px",
        position: "absolute",
        bottom: 0,
        left: 0,
        backgroundColor: "black",
        transform: "scaleX(0.5)",
        transformOrigin: "bottom left",
        transition: "transform 0.25s ease-out",
      },
      "&:hover::after": {
        transform: "scaleX(1)",
        height: "2.5px",
        transformOrigin: "bottom left",
      },
    },
  };
  return (
    <Stack
      sx={{
        position: "absolute",
        width: "65%",
        flexDirection: "column",
        padding: { xs: "20px", sm: "30px", md: "40px", lg: "50px" },
        backgroundColor: "#FFFDF5",
        top: { xs: "50%", lg: "78%" },
        left: "17%",
      }}
    >
      <Typography variant="h3" sx={{ textTransform: "uppercase", fontWeight: "900", fonSize: "48px", marginLeft: "16px", marginBottom: "25px" }}>
        Our Story
      </Typography>
      <Typography variant="body1" sx={{ textAlign: "justify", padding: "0 16px" }}>
        We are an IT service company, and here to help you expand your business by creating unique and professional websites, we enhance the
        credibility and power of your brand. By leveraging cutting-edge technologies and utilizing digital marketing strategies, we implement the best
        practices to attract new customers and increase your sales. You can witness sustainable growth and the advanced development of your business.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%", padding: "20px" }}>
        <Link style={{ color: "#000", position: "relative", width: "150px" }} to={"/our-team"}>
          <Typography component="span" sx={styles.underlineAnimation} textTransform={"uppercase"}>
            meet our team
          </Typography>
        </Link>
      </Box>
    </Stack>
  );
};

export default AboutCard;
