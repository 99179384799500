import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    border: "1px solid rgba(0, 0, 0)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid rgba(0, 0, 0)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const HumanResources = () => {
  const bodyRows = [
    {
      role: "Frontend",
      Level: "Mid-level",
      Increase: "60,000",
    },
    {
      role: "Frontend",
      Level: "Senior",
      Increase: "80,000",
    },
    {
      role: "Backend",
      Level: "Mid-level",
      Increase: "60,000",
    },
    {
      role: "Backend",
      Level: "Senior",
      Increase: "80,000",
    },
    {
      role: "Design",
      Level: "Senior",
      Increase: "80,000",
    },
    {
      role: "Business Analytics",
      Level: "Senior",
      Increase: "80,000",
    },
    {
      role: "Scrum master",
      Level: "Senior",
      Increase: "80,000",
    },
    {
      role: "CI/CD",
      Level: "Senior",
      Increase: "180,000",
    },
  ];

  return (
    <TableContainer
      sx={{
        maxWidth: 700,
        boxShadow: "30px 30px 0px -16px rgb(255, 253, 245),30px 30px 0px -15px rgba(0,0,0)",
      }}
      component={Paper}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={3}>
              <Typography variant="h4" fontWeight={"bolder"} textTransform={"uppercase"} textAlign={"center"}>
                human resources
              </Typography>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell sx={{ minWidth: "200px" }}>
              <Typography variant="h6" fontWeight={"bolder"} textTransform={"uppercase"} textAlign={"center"}>
                Role
              </Typography>
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "200px" }}>
              <Typography variant="h6" fontWeight={"bolder"} textTransform={"uppercase"} textAlign={"center"}>
                Level
              </Typography>
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: "200px" }}>
              <Typography variant="h6" fontWeight={"bolder"} textTransform={"uppercase"} textAlign={"center"}>
                Increase (pounds)
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyRows.map((row) => (
            <StyledTableRow key={row.title}>
              <StyledTableCell component="th" scope="row" align="center">
                <Typography variant="caption" fontSize={15}>
                  {row.role}
                </Typography>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="center">
                <Typography variant="caption" fontSize={15}>
                  {row.Level}
                </Typography>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="center">
                <Typography variant="caption" fontSize={15}>
                  {row.Increase}
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          ))}
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" align="right" colSpan={2}>
              <Typography variant="caption" fontSize={20} fontWeight={"bold"} paddingRight={3}>
                Sum:
              </Typography>
            </StyledTableCell>
            <StyledTableCell component="th" scope="row" align="center">
              <Typography variant="caption" fontSize={15} fontWeight={"bold"}>
                700,000
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HumanResources;
