import React, { useState } from "react";
import { Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, SvgIcon, Box, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

import { ReactComponent as HomeIcon } from "../../assets/header/home.svg";
import { ReactComponent as ServicesIcon } from "../../assets/header/services.svg";
import { ReactComponent as BuildWebsite } from "../../assets/header/build-website.svg";
import { ReactComponent as Pricing } from "../../assets/header/pricing.svg";
import { ReactComponent as About } from "../../assets/header/about.svg";

import Logo from "../../assets/legacy-logo.png";

const pages = [
  { itemName: "Home", icon: HomeIcon, itemRoute: "/" },
  { itemName: "Services", icon: ServicesIcon, itemRoute: "/services" },
  { itemName: "Build Website", icon: BuildWebsite, itemRoute: "/build-website" },
  { itemName: "Pricing", icon: Pricing, itemRoute: "/pricing" },
  { itemName: "About", icon: About, itemRoute: "/about" },
];
const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const styles = {
    linkItem: {
      textDecoration: "none",
      color: "#000",
    },
    menuBtn: { color: "black", marginLeft: "auto" },
    drawer: {},
  };

  return (
    <React.Fragment>
      <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List sx={{ minWidth: "250px", padding: "25px" }}>
          <Stack alignItems="center" mb="2rem">
            <Box component="img" sx={{ width: "100%", maxWidth: "20vw", minWidth: "8rem" }} src={Logo} alt="logo" />
          </Stack>
          {pages.map((page, index) => (
            <ListItemButton key={index}>
              <ListItemIcon sx={{ alignItems: "center" }}>
                {<SvgIcon component={page.icon} sx={{ mr: "8px" }} inheritViewBox />}
                <ListItemText>
                  <Link style={styles.linkItem} to={page.itemRoute}>
                    {page.itemName}
                  </Link>
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <IconButton sx={styles.menuBtn} onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon color="black" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
