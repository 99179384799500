import React from "react";
import { Heading, ServiceSection1, ServiceSection2, ServiceSection3, ServiceTitle } from "./components";

function BuildWebsite(props) {
  return (
    <main style={{ paddingTop: "2.5rem", paddingBottom: "4rem" }}>
      <Heading />
      <ServiceTitle />
      <ServiceSection1 />
      <ServiceSection2 />
      <ServiceSection3 />
    </main>
  );
}

export default BuildWebsite;
