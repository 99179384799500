import { createTheme, responsiveFontSizes, ThemeProvider as ThemeProviderMui } from "@mui/material";

function ThemeProvider({ children }) {
  let theme = createTheme({
    palette: {
      primary: {
        main: "#220023",
      },
      background: {
        default: "#FFFDF5",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollbarColor: "#6b6b6b #2b2b2b",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#EAEEF5",
              maxHeight: 8,
              maxWidth: 10,
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              background: "linear-gradient(180deg, #220023 0%, #220023EE 58.95%, #220023FF 100%)",
              border: "2px solid #FFFFFF",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
              backgroundColor: "#B9B9B9",
            },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
              backgroundColor: "#B9B9B9",
            },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#B9B9B9",
            },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#B9B9B9",
            },
          },
        },
      },
    },
    typography: {
      fontFamily: [
        "Montserrat",
        "Lemon Milk Font",
        "Adobe Gothic std B",
        "sans-serif",
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
      ].join(","),
      h1: {
        fontFamily: [
          "Lemon Milk Font",
          "Montserrat",
          "Adobe Gothic std B",
          "sans-serif",
          "Apple Color Emoji",
          "Segoe UI Emoji",
          "Segoe UI Symbol",
        ].join(","),
      },
      h2: {
        fontFamily: [
          "Lemon Milk Font",
          "Montserrat",
          "Adobe Gothic std B",
          "sans-serif",
          "Apple Color Emoji",
          "Segoe UI Emoji",
          "Segoe UI Symbol",
        ].join(","),
      },
      h3: {
        fontFamily: [
          "Lemon Milk Font",
          "Montserrat",
          "Adobe Gothic std B",
          "sans-serif",
          "Apple Color Emoji",
          "Segoe UI Emoji",
          "Segoe UI Symbol",
        ].join(","),
      },
      h4: {
        fontFamily: [
          "Lemon Milk Font",
          "Montserrat",
          "Adobe Gothic std B",
          "sans-serif",
          "Apple Color Emoji",
          "Segoe UI Emoji",
          "Segoe UI Symbol",
        ].join(","),
      },
      h5: {
        fontFamily: [
          "Lemon Milk Font",
          "Montserrat",
          "Adobe Gothic std B",
          "sans-serif",
          "Apple Color Emoji",
          "Segoe UI Emoji",
          "Segoe UI Symbol",
        ].join(","),
      },
      h6: {
        fontFamily: [
          "Lemon Milk Font",
          "Montserrat",
          "Adobe Gothic std B",
          "sans-serif",
          "Apple Color Emoji",
          "Segoe UI Emoji",
          "Segoe UI Symbol",
        ].join(","),
      },
    },
  });

  const responsiveTheme = responsiveFontSizes(theme);

  return <ThemeProviderMui theme={responsiveTheme}>{children}</ThemeProviderMui>;
}

export default ThemeProvider;
