import { Typography, Box } from "@mui/material";


const styles = {
    heading:{
        textAlign:"center",
        backgroundColor:"primary.main",
        color: "#FFF",
        padding: "1rem 0"
    },
}
export const Sheading = () => {
  return (
    <Box sx={styles.heading} >
      <Typography component="h2" variant="h3">our services</Typography>
    </Box>
  );
};
