import { Typography, Box, Stack, Container } from "@mui/material";
import baanArchitects from "../../../assets/home/baan-architects.png";
import ballymac from "../../../assets/home/ballymac.png";
import lambdaestates from "../../../assets/home/lambdaestates.png";
import { Link } from "react-router-dom";

const styles = {
  root: { paddingTop: "5rem" },
  text1: {
    color: "#B9B9B9B9",
    fontSize: { xs: "13px", sm: "18px", md: "22px" },
    letterSpacing: "3px",
  },
  text2: {
    letterSpacing: ".3rem",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      display: { xs: "none", sm: "inline" },
      top: "50%",
      left: { xs: "20rem", sm: "24rem", md: "27rem" },
      border: "gray 1px solid",
      width: { xs: "60px", md: "100px" },
    },
  },
  para: {
    maxWidth: "36rem",
    lineHeight: "1.8rem",
    marginBottom: "3rem",
  },
  boxes: {
    width: "24rem",
    color: { xs: "#000", lg: "#FFF" },
  },
  containerBoxes: {
    display: "flex",
    justifyContent: "center",
    flexWrap: { xs: "wrap", lg: "nowrap" },
    marginBottom: "8rem",
    gap: "2rem 5rem",
  },
  bgBottom: {
    width: "100%",
    height: "40rem",
    backgroundColor: "primary.main",
    marginTop: "-38rem",
  },
  underlineAnimation: {
    transition: "all 0.3s",
    position: "absolute",
    "&::after": {
      content: '""',
      width: "100%",
      height: "2px",
      position: "absolute",
      bottom: 0,
      left: 0,
      backgroundColor: "black",
      transform: "scaleX(0.5)",
      transformOrigin: "bottom left",
      transition: "transform 0.25s ease-out",
    },
    "&:hover::after": {
      transform: "scaleX(1)",
      height: "2.5px",
      transformOrigin: "bottom left",
    },
  },
};
export const DoneProjects = () => {
  return (
    <>
      <Container sx={styles.root}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", gap: "0 4rem", marginBottom: "2rem" }}>
          <Stack>
            <Typography component="h2" fontFamily="Lemon Milk Font" sx={styles.text1} gutterBottom>
              DONE PROJECTS
            </Typography>
            <Typography component="h3" variant="h3" sx={styles.text2} gutterBottom>
              WHAT WE DID
            </Typography>
            <Typography component="p" variant="body1" sx={styles.para}>
              We are proud to have helped all of our previous clients achieve their online marketing goals. We are committed to providing our clients
              with the highest quality services and helping them succeed in today's competitive online landscape.
            </Typography>
          </Stack>
          {/* <Link style={{ color: "#000" }} to={""}>
            <Typography component="span" sx={styles.underlineAnimation}>
              SEE ALL
            </Typography>
          </Link> */}
        </Box>
        <Box sx={styles.containerBoxes}>
          <Stack sx={styles.boxes}>
            <Box component="img" maxWidth="100%" src={ballymac} marginBottom="1.5rem" />
            <Typography component="h3" variant="h5" gutterBottom>
              Ballymac Group LTD
            </Typography>
            <Typography textAlign={"justify"}>
              Ballymac Group LTD's website delivers a distinctive real estate experience, merging expertise with innovation. Specializing in SA and
              HMO rentals, bespoke deal selling, property management, and investments, they ensure personalized transactions and maximize investment
              potential. Committed to excellence and integrity, their transformative property experiences elevate the real estate journey.
            </Typography>
            <Box sx={{ marginTop: "50px", textAlign: "center" }}>
              <Link to={"https://ballymacgroup.com/"}>
                <Typography
                  component="span"
                  sx={{
                    ...styles.underlineAnimation,
                    position: "relative",
                    "&::after": { ...styles.underlineAnimation["&::after"], backgroundColor: "white" },
                  }}
                >
                  SEE PROJECT
                </Typography>
              </Link>
            </Box>
          </Stack>
          <Stack sx={styles.boxes}>
            <Box component="img" maxWidth="100%" src={lambdaestates} marginBottom="1.5rem" />
            <Typography component="h3" variant="h5" gutterBottom>
              Lambda
            </Typography>
            <Typography textAlign={"justify"}>
              Lambda is a real estate company that redefines the industry by integrating deal sourcing, innovative rent-to-rent solutions, and
              enhancing living experiences. Focusing on income assurance, smart investments, and profitable investments, Lambda streamlines renting
              and selling processes, ensuring a hassle-free experience for clients.
            </Typography>
            <Box sx={{ marginTop: "96px", textAlign: "center" }}>
              <Link to={"https://lambdaestates.co.uk/"}>
                <Typography
                  component="span"
                  sx={{
                    ...styles.underlineAnimation,
                    position: "relative",
                    "&::after": { ...styles.underlineAnimation["&::after"], backgroundColor: "white" },
                  }}
                >
                  SEE PROJECT
                </Typography>
              </Link>
            </Box>
          </Stack>
          <Stack sx={styles.boxes}>
            <Box component="img" maxWidth="100%" src={baanArchitects} marginBottom="1.5rem" />
            <Typography component="h3" variant="h5" color="#fff" gutterBottom>
              BAAN ARCHITECTS
            </Typography>
            <Typography color="#fff" textAlign={"justify"}>
              BAAN ARCHITECTS, a 2016-founded firm, focuses on architectural innovation by redefining challenges through program, form, context, and
              structure. The firm embraces plurality and individuality, fostering a dynamic dialogue among team members. BAAN Architects creates
              diverse architectural concepts, embracing the plurality and diversity of thought, ensuring each project tells a unique story.
            </Typography>
            <Box sx={{ marginTop: "70px", textAlign: "center" }}>
              <Link to={"https://baan-architects.com/"}>
                <Typography
                  component="span"
                  sx={{
                    ...styles.underlineAnimation,
                    position: "relative",
                    "&::after": { ...styles.underlineAnimation["&::after"], backgroundColor: "white" },
                  }}
                >
                  SEE PROJECT
                </Typography>
              </Link>
            </Box>
          </Stack>
        </Box>
        <Stack gap="2rem">
          <Typography alignSelf="center" variant="h3" fontSize={{ xs: "1.25rem", sm: "1.5rem", md: "3rem" }} mb={2} color="#fff">
            WE WERE SURE YOU’D WANT THIS
          </Typography>
          {/* <Link style={{ color: "#fff", marginBottom: "30px", width: "100%", display: "flex", justifyContent: "flex-end" }} to={""}>
            <Typography
              sx={{
                ...styles.underlineAnimation,
                "&::after": { ...styles.underlineAnimation["&::after"], backgroundColor: "#fff" },
              }}
            >
              BOOK A MEETING
            </Typography>
          </Link> */}
        </Stack>
      </Container>
      <Box sx={styles.bgBottom}></Box>
    </>
  );
};
