import { Box, Typography } from "@mui/material";

const SampleCard = ({ number, title, description }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        maxWidth: { xs: "330px", md: "450px", lg: "450px" },
        gap: "30px",
      }}
    >
      <Typography color={"rgb(134, 117, 134)"} variant="h1">
        {number}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Typography sx={{ fontWeight: 800 }} variant="h5">
          {title}
        </Typography>
        <Typography variant="caption" sx={{ lineHeight: "22px", textAlign: "justify" }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default SampleCard;
