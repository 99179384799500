import React from "react";

import { HeaderHero } from "./components/HeaderHero";
import { HeaderImage } from "./components/HeaderImage";
import { SeoIconLine } from "./components/SeoIconLine";
import { Introductory } from "./components/Introductory";
import { SeoChallangeSection } from "./components/SeoChallanges/SeoChallangeSection";
import { WhatWeDo } from "./components/WhatWeDo/WhatWeDo";
import { Page } from "./components/Page";

function Seo(props) {
  return (
    <>
      <HeaderHero />
      <HeaderImage />
      <SeoIconLine />
      <Introductory />
      <SeoChallangeSection />
      <WhatWeDo />
    </>
  );
}

export default Seo;
