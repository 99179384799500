import { Box } from "@mui/material";
import HeaderImagePhoto from "../../../assets/ceo-banner.png";

const styles = {
  root: {
    width: "100%",
    overflow: "hidden",
  },
  img: {
    width: "100%",
    height: "862px",
    display: "block",
    objectFit: "cover",
  },
};

export const HeaderImage = () => {
  return (
    <Box sx={styles.root}>
      <Box component="img" src={HeaderImagePhoto} alt="Hero Image" sx={styles.img}></Box>
    </Box>
  );
};
