import React from "react";
import { Heading } from "./components/Heading";
import { Box } from "@mui/material";
import heroPic from "../../assets/home/heroPic.png";
import { About } from "./components/About";
import { Slider } from "./components/Slider";
import { SecondSlider } from "./components/SecondSlider";
import { OurServices } from "./components/OurServices";
import { DoneProjects } from "./components/DoneProjects";
import { OurProfession } from "./components/OurProfession";
const styles = {
  imgBox: {
    marginTop: "2rem",
    textAlign: "center",
    maxWidth: "100%",
    height: "auto",
    backgroundColor: "primary.main",
  },
  img: {
    width: "100%",
  },
};
function Home() {
  return (
    <>
      <Heading />
      <Box sx={styles.imgBox}>
        <Box component="img" src={heroPic} alt="img" sx={styles.img}></Box>
      </Box>
      <Box sx={{ overflow: "clip" }}>
        <Slider />
      </Box>
      <About />
      <Box sx={{ overflow: "clip" }}>
        <SecondSlider />
      </Box>
      <OurServices />
      <DoneProjects />
      <OurProfession />
    </>
  );
}

export default Home;
