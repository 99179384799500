// CeoCard.js
import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const styles = {
  CardBody: {
    px: 4,
    pt: 2,
    width: "100%",
    height: "100%",
    border: "1px solid #000",
  },
  CardTitle: {
    fontWeight: 900,
    lineHeight: "45.6px",
    textTransform: "uppercase",
    mb: 1,
  },
  CardContent: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "justify",
    fontFamily: "Montserrat",
    textTransform: "lowercase",
  },
};

const SeoCard = ({ item }) => {
  return (
    <Box sx={styles.CardBorder}>
      <Card sx={{ height: "250px", boxShadow: "30px 30px 0px -16px #FFFDF5,30px 30px 0px -15px rgba(0,0,0)" }}>
        <CardContent sx={styles.CardBody}>
          <Typography variant="h5" sx={styles.CardTitle}>
            {item.title}
          </Typography>
          <Typography variant="body2" sx={styles.CardContent}>
            {item.description}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SeoCard;
