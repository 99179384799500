import { Box, Container, Stack, Typography } from "@mui/material";
import headingImage from "../../../assets/build-website/heading.svg";

export const Heading = () => {
  return (
    <Box>
      <Container>
        <Typography
          sx={{ padding: { lg: "0 50px", xs: "0 32px" }, marginBottom: "10px" }}
          variant="h3"
          textTransform={"uppercase"}
          letterSpacing={{ xs: 4, md: 8 }}
          fontSize={{ xs: "1.8rem", md: "2.5rem" }}
        >
          Unleash Your Digital Potential:
        </Typography>
        <Typography
          sx={{ padding: { lg: "0 50px", xs: "0 32px" } }}
          variant="h2"
          fontWeight={"bold"}
          letterSpacing={{ xs: 4, md: 8 }}
          fontSize={{ xs: "32px", md: "60px" }}
        >
          We Build, You Shine!
        </Typography>
      </Container>
      <Stack mt={6}>
        <img src={headingImage} alt="heading-image" />
      </Stack>
    </Box>
  );
};
