import { Typography, Container } from "@mui/material";

const styles = {
  Header1: {
    color: "#151414",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "72px",
    letterSpacing: "3.5px",
    textTransform: "uppercase",
    "@media (max-width: 600px)": {
      fontSize: "1.4rem",
      letterSpacing: "0",
      lineHeight: "32px",
    },
  },
  Header2: {
    color: "#151414",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "72px",
    textTransform: "uppercase",
    mb: 4,
    fontSize: "59px",
    letterSpacing: "9.44px",
    "@media (max-width: 600px)": {
      fontSize: "1.3rem",
      letterSpacing: "0",
      lineHeight: "32px",
      mt: 2,
    },
  },
};

export const HeaderHero = () => {
  return (
    <Container>
      <Typography variant="h3" sx={styles.Header1}>
        SEO: The Key to Unbelievable Digital
      </Typography>
      <Typography variant="h3" sx={styles.Header2}>
        Marketing Strategy Success!
      </Typography>
    </Container>
  );
};
