import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    border: "1px solid rgba(0, 0, 0)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid rgba(0, 0, 0)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const FunctionalityTable = () => {
  const bodyRows = [
    {
      FUNCTIONALITIES: "Payment Process",
      COST: "£250",
    },
    {
      FUNCTIONALITIES: "Calendar and Booking",
      COST: "£300",
    },
    {
      FUNCTIONALITIES: "Live Chat",
      COST: "£250 (Discounted price of £50)",
    },
    {
      FUNCTIONALITIES: "Upload product",
      COST: "£100",
    },
    {
      FUNCTIONALITIES: "Blog",
      COST: "£500",
    },
    {
      FUNCTIONALITIES: "content creation",
      COST: "£150",
    },
    {
      FUNCTIONALITIES: "Logo design",
      COST: "Starting at £250",
    },
    {
      FUNCTIONALITIES: "Business card design",
      COST: "Starting at £200",
    },
    {
      FUNCTIONALITIES: "Advertising poster design",
      COST: "Starting at £150",
    },
    {
      FUNCTIONALITIES: "instagram profile template",
      COST: "£250",
    },
    {
      FUNCTIONALITIES: "instagram post template",
      COST: "£300",
    },
    {
      FUNCTIONALITIES: "Company register in UK",
      COST: "It depends on your company",
    },
    {
      FUNCTIONALITIES: "Business plan",
      COST: "It depends on your business",
    },
  ];

  return (
    <TableContainer
      sx={{
        maxWidth: 600,
        boxShadow: "30px 30px 0px -16px rgb(255, 253, 245),30px 30px 0px -15px rgba(0,0,0)",
      }}
      component={Paper}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Typography variant="h6">FUNCTIONALITIES</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="h6">COST</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyRows.map((row) => (
            <StyledTableRow key={row.title}>
              <StyledTableCell component="th" scope="row">
                <Typography variant="caption">{row.FUNCTIONALITIES}</Typography>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                <Typography variant="caption">{row.COST}</Typography>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FunctionalityTable;
