import { Typography, Box, Stack, Container } from "@mui/material";
import img from "../../../assets/home/img.png";
import { Link } from "react-router-dom";

const styles = {
  root: { padding: "7rem 0" },
  text1: {
    color: "#B9B9B9B9",
    fontSize: { xs: "13px", sm: "18px", md: "22px" },
    letterSpacing: "3px",
  },
  text2: {
    letterSpacing: ".3rem",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      display: { xs: "none", sm: "inline" },
      left: { xs: "20rem", sm: "24rem", md: "27rem" },
      border: "gray 1px solid",
      width: { xs: "60px", md: "100px" },
    },
  },
  para: {
    maxWidth: "35rem",
    lineHeight: "1.8rem",
    marginBottom: "3rem",
  },
  top: {
    display: "flex",
    flexWrap: "wrap-reverse",
    justifyContent: { xs: "center", lg: "space-between" },
    alignItems: "center",
    gap: "5rem",
    padding: "0 1.5rem",
  },
  underlineAnimation: {
    transition: "all 0.3s",
    position: "absolute",
    color: "#000",
    "&::after": {
      content: '""',
      width: "100%",
      height: "2px",
      position: "absolute",
      bottom: 0,
      left: 0,
      backgroundColor: "black",
      transform: "scaleX(0.5)",
      transformOrigin: "bottom left",
      transition: "transform 0.25s ease-out",
    },
    "&:hover::after": {
      transform: "scaleX(1)",
      height: "2.5px",
      transformOrigin: "bottom left",
    },
  },
};
export const OurProfession = () => {
  return (
    <>
      <Container sx={styles.root}>
        <Box sx={styles.top}>
          <Stack alignItems={{ xs: "start", lg: "end" }}>
            <Typography alignSelf="start" component="h2" fontFamily="Lemon Milk Font" sx={styles.text1} gutterBottom>
              OUR profession
            </Typography>
            <Typography alignSelf="start" component="h3" variant="h3" sx={styles.text2} gutterBottom>
              Build website
            </Typography>
            <Typography alignSelf="start" component="p" variant="body1" textAlign={"justify"} sx={styles.para}>
              At our company, we understand that having a website is not just about having an online presence, but is also about creating a platform
              that drives traffic and converts visitors into customers. That’s why we take a meticulous approach to web design by analyzing every
              aspect of your business to create a website that is tailored to your specific needs. By dissecting your business, we can identify the
              strengths and weaknesses of your current online presence and develop a comprehensive strategy to improve your conversion rates and
              produce positive results. Our goal is to create a website that not only looks great but also delivers measurable results, helping you
              achieve your business objectives and stand out in a competitive online marketplace.
            </Typography>
            <Link to={"/build-website"}>
              <Typography component="span" sx={styles.underlineAnimation}>
                SEE ALL
              </Typography>
            </Link>
          </Stack>
          <Box component="img" maxWidth="100%" sx={{ boxShadow: "17px -17px 0px #220023" }} src={img} alt="img" />
        </Box>
      </Container>
    </>
  );
};
