import { Grid, Typography } from "@mui/material";

const AboutItem = ({ number, title, text }) => {
  return (
    <Grid container spacing={0} sx={{ width: { xs: "100%", md: "80%" }, color: "#FFFFFF" }}>
      <Grid item xs={2}>
        <Typography
          variant="h1"
          align="right"
          sx={{
            color: "#eaeaea80",
            marginTop: "12px",
            fontWeight: 900,
          }}
        >
          {number}
        </Typography>
      </Grid>

      <Grid item xs={10} sx={{ padding: "15px" }}>
        <Typography
          variant="h6"
          sx={{
            color: "#ffff",
            textTransform: "uppercase",
            fontWeight: 700,
            marginBottom: "30px",
          }}
        >
          {title}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "justify" }}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AboutItem;
