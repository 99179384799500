import { Typography, Container } from "@mui/material";

const styles = {
  root: {
    bgcolor: "#FFFDF5",
    padding: 5,
    textAlign: "start",
  },
  HeaderText: {
    fontWeight: 800,
    mb: 4,
    "@media (max-width: 600px)": {
      fontSize: "1.3rem",
      letterSpacing: "0",
      lineHeight: "32px",
      mt: 2,
    },
  },
  DescriptionText: {
    fontWeight: 500,
    textAlign: "justify",
    fontFamily: "Montserrat",
  },
};

export const Introductory = () => {
  return (
    <Container sx={styles.root}>
      <Typography variant="h3" sx={styles.HeaderText}>
        ELEVATE YOUR ONLINE SUCCESS WITH EXPERT SEO SOLUTIONS
      </Typography>
      <Typography variant="body1" sx={styles.DescriptionText}>
        Our professional SEO company is dedicated to enhancing your website ranking through cutting-edge Search Engine Optimization (SEO) techniques.
        With a strong focus on organic search growth and technical expertise, we deliver the best results for businesses worldwide. Experience
        remarkable improvement in your online visibility and also by using a professional graphic design achieve sustainable success in the
        ever-competitive digital landscape. Trust our acclaimed SEO solutions for exceptional outcomes.{" "}
      </Typography>
    </Container>
  );
};
