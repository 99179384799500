import { Stack, Typography, Grid, Box, Container } from "@mui/material";
import aboutImg from "../../assets/about.jpg";
import AboutItem from "./components/AboutItem";
import AboutFooter from "./components/AboutFooter";
import AboutCard from "./components/AboutCard";

function About() {
  return (
    <>
      <Stack sx={{ background: "#FFFDF5" }}>
        <Typography
          variant="h3"
          align="center"
          sx={{ fontSize: "20px", padding: "40px 0px", letterSpacing: "8px", textTransform: "uppercase", fontWeight: "700" }}
        >
          APSLegacy, IT Service Company
        </Typography>
        <Stack sx={{ position: "relative", width: "100%" }}>
          <Box component="img" src={aboutImg} alt="about page pic" sx={{ width: "100%", height: "auto" }} />
          <AboutCard />
        </Stack>
      </Stack>
      <Stack
        sx={{
          paddingTop: { xs: "800px", sm: "400px", md: "400px", lg: "350px", xl: "320px" },
          paddingBottom: "100px",
          background: "#220023",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack sx={{ width: "71%", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="body1" align="center" sx={{ color: "#FFFFFF", textTransform: "uppercase", paddingBottom: "5px" }}>
            Become one of us!
          </Typography>
          <Typography variant="h3" align="center" sx={{ paddingBottom: "40px", color: "#FFFFFF", textTransform: "uppercase" }}>
            Why choose us
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "justify",
              marginBottom: "30px",
              width: "80%",
              font: "20px",
              color: "#FFFFFF",
            }}
          >
            We listen to your conditions precisely and also produce a website or app that rates well across parameters similar to branding, design,
            and overall stoner experience. All the websites and apps we produce are fully customized and responsive. This gives our guests the
            capability to reach out to a wider audience. The process we follow is simple and transparent, so you know exactly where your design stands
            at every stage. Our client-centric approach ensures that we direct our energy into creating fully distinct websites and apps that have the
            asked effect, be it adding leads or boosting deals.
          </Typography>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={6} sx={{ padding: { xs: "40px", lg: "20px" }, paddingTop: { lg: "40px" } }}>
              <AboutItem
                number="1"
                title="Merging Business Needs with Modern Design Expertise"
                text="We produce websites & apps acclimatized to meet the requirements of your business, your assiduity, and not to forget, your guests. We use our moxie to produce well-designed responsive websites & apps that push the boundaries of ultramodern-day web design."
              />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ padding: { xs: "40px", lg: "20px" }, paddingTop: { lg: "40px" } }}>
              <AboutItem
                number="2"
                title="Strategic Web Development for Global Growth"
                text="Our team includes technical managers, web developers, designers, and SEO specialists who analyze your goals and target audience, as well as user interface, to put us in a better position to develop a website that fulfills your specific needs. Moreover, we enable you to register a company in the UK, an important milestone for international business expansion."
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Stack
        style={{
          background: "#FFFDF5",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "50px",
        }}
      >
        <AboutFooter />
      </Stack>
    </>
  );
}

export default About;
