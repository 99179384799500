import { Stack, Typography, Box } from "@mui/material";
import serviceIcon from "../../../assets/build-website/Union.svg";

export const ServiceTitle = () => {
  return (
    <Stack>
      <Typography
        bgcolor="primary.main"
        color="white"
        display="flex"
        justifyContent="center"
        alignItems="center"
        columnGap={2}
        py={{ xs: 2, md: 4 }}
        variant="h3"
        fontSize={{ xs: "1rem", md: "3rem" }}
      >
        WEB DEVELOPMENT
        <Box component="img" width={{ xs: 24, md: 48 }} alt="service-icon" src={serviceIcon} />
      </Typography>
    </Stack>
  );
};
